<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-outline</v-icon>不足編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="invoiceForm" v-model="validEntryForm" lazy-validation>
        <template>
          <v-card-subtitle>不足情報</v-card-subtitle>
          <v-container style="max-width: inherit">
            <v-row dense>
              <v-col cols="12" sm="3" v-if="allowedAction(constants.supplierActions)">
                <dp-date-picker label="登録日" v-model="updateModel.createDatetime" type="date" filled dense disabled>
                </dp-date-picker>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field label="不足番号" v-model="updateModel.deficiencyNumber" filled dense disabled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" v-if="allowedAction(constants.cafereoActions)">
                <v-select
                  :items="cafereoDeficiencyStatuses"
                  label="不足ステータス"
                  dense
                  filled
                  disabled
                  v-model="updateModel.deficiencyStatus"
                  attach
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" v-if="allowedAction(constants.supplierActions)">
                <v-select
                  :items="makerDeficiencyStatuses"
                  label="ステータス"
                  dense
                  filled
                  disabled
                  v-model="updateModel.deficiencyStatus"
                  attach
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </template>
        <v-card-subtitle>発注情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="発注ID" v-model="updateModel.purchaseId" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="2">
              <dp-date-picker
                label="登録日"
                v-model="updateModel.createDatetime"
                filled
                dense
                disabled
                type="Date"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field label="仕入先略称" v-model="updateModel.maker" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="JANCODE" v-model="updateModel.jancode" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field label="タイトル" v-model="updateModel.title" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field label="商品名" v-model="updateModel.productName" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="3">
              <v-text-field label="上代" v-model="updateModel.retailPrice" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="仕入掛率" v-model="updateModel.purchaseRate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="仕切単価" v-model="updateModel.unitPrice" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="発注数" v-model="updateModel.purchaseQuantity" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.supplierActions)">
            <v-col cols="12" sm="2">
              <v-text-field label="納品数" v-model="updateModel.purchaseQuantity" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="isCafereoUser"></v-divider>
        <v-card-subtitle>不足情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="不足数"
                v-model="updateModel.deficiencyQuantity"
                filled
                dense
                @change="updateModel.deficiencyQuantity = $event !== '' ? $event : null"
                disabled
                :rules="[
                  rules.required,
                  rules.isNumber,
                  rules.maxLength(8),
                  rules.isMinNumber(1),
                  rules.validDeficiencyQuantity,
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-switch
                label="補填品有無"
                inset
                dense
                v-model="updateModel.replacementExistence"
                :disabled="isReplacementExistence"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" v-if="allowedAction(constants.supplierActions)">
              <dp-date-picker
                label="納品日"
                v-model="updateModel.deliveryReservation"
                filled
                dense
                type="date"
                :disabled="
                  !(
                    updateModel.deficiencyStatus == makerDeficiencyStatuses[2].value ||
                    updateModel.deficiencyStatus == makerDeficiencyStatuses[3].value
                  )
                "
                :rules="[rules.required]"
              >
              </dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="補填品数"
                v-model="updateModel.replacementQuantity"
                filled
                dense
                @change="updateModel.replacementQuantity = $event !== '' ? $event : null"
                :disabled="!updateModel.replacementExistence"
                :rules="[rules.isNumber, rules.maxLength(8), rules.validReplacementQuantity]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="4">
              <dp-date-picker
                label="納品予約"
                v-model="updateModel.deliveryReservation"
                filled
                dense
                type="date"
                :disabled="
                  !(updateModel.replacementQuantity && updateModel.replacementQuantity >= 1) ||
                  updateModel.deficiencyStatus > cafereoDeficiencyStatuses[4].value
                "
              >
              </dp-date-picker>
            </v-col>
            <v-col cols="12" sm="5">
              <v-select
                :items="deliveryCompanyList"
                label="配送業者"
                dense
                filled
                :disabled="!(updateModel.replacementQuantity && updateModel.replacementQuantity >= 1)"
                v-model="updateModel.deliveryCompany"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="isCafereoUser">
            <v-col cols="12" sm="3">
              <v-switch
                label="検品結果"
                inset
                dense
                v-model="updateModel.inspectionResult"
                :disabled="!updateModel.deliveryReservation"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="検品数"
                v-model="updateModel.inspectionQuantity"
                filled
                dense
                @change="updateModel.inspectionQuantity = $event !== '' ? $event : null"
                :disabled="!updateModel.inspectionResult"
                :rules="[rules.isNumber, rules.maxLength(8), rules.validInspectionQuantity]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="isCafereoUser"></v-divider>
        <v-card-subtitle v-if="isCafereoUser">履歴</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="isCafereoUser">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="更新日時"
                v-model="updateModel.updateDatetimeDisp"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="登録日時"
                v-model="updateModel.createDatetimeDisp"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import Validation from "../../libs/validation";
import CafereoDeficiencyStatuses from "../../consts/deficiencyStatus/CafereoDeficiencyStatuses";
import MakerDeficiencyStatuses from "../../consts/deficiencyStatus/MakerDeficiencyStatuses";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "DeficiencyEntry",
  props: ["inputModel", "title"],
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C030402"],
        supplierActions: ["M020404"],
      },
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isMinNumber: Validation.isMinNumber,
        validDeficiencyQuantity: (value) => this.validDeficiencyQuantity(value),
        validReplacementQuantity: (value) => this.validReplacementQuantity(value),
        validInspectionQuantity: (value) => this.validInspectionQuantity(value),
      },
      updateModel: {},
      validEntryForm: null,
      cafereoDeficiencyStatuses: CafereoDeficiencyStatuses.all(),
      makerDeficiencyStatuses: MakerDeficiencyStatuses.all(),
      deliveryCompanyList: DeliveryCompanys.all(),
    };
  },
  computed: {
    isAssign() {
      return this.assign != null && this.assign !== false;
    },
    isReplacementExistence() {
      if (this.allowedAction(this.constants.cafereoActions)) {
        return false;
      } else {
        if (this.updateModel.deficiencyStatus == MakerDeficiencyStatuses.unanswered) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      // 引数を設定
      this.updateModel = JSON.parse(JSON.stringify(inputModel));
      this.updateModel.createDatetimeDisp = moment(this.updateModel.createDatetime).format("YYYY/MM/DD HH:mm:ss");
      this.updateModel.updateDatetimeDisp = moment(this.updateModel.updateDatetime).format("YYYY/MM/DD HH:mm:ss");
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          let requestContents = {
            deficiency: {
              deficiencyNumber: this.updateModel.deficiencyNumber,
              purchaseId: this.updateModel.purchaseId,
              deficiencyQuantity: Number(this.updateModel.deficiencyQuantity),
              replacementExistence: this.updateModel.replacementExistence,
              replacementQuantity: this.updateModel.replacementQuantity
                ? Number(this.updateModel.replacementQuantity)
                : null,
              deliveryReservation: this.updateModel.deliveryReservation ? this.updateModel.deliveryReservation : null,
              deliveryCompany: this.updateModel.deliveryCompany ? Number(this.updateModel.deliveryCompany) : null,
              inspectionResult: this.updateModel.inspectionResult,
              inspectionQuantity: this.updateModel.inspectionQuantity
                ? Number(this.updateModel.inspectionQuantity)
                : null,
              updateDatetime: this.updateModel.updateDatetime,
            },
          };
          if (this.allowedAction(this.constants.supplierActions)) {
            requestContents = {
              deficiency: {
                deficiencyNumber: this.updateModel.deficiencyNumber,
                replacementExistence: this.updateModel.replacementExistence,
                replacementQuantity: this.updateModel.replacementQuantity
                  ? Number(this.updateModel.replacementQuantity)
                  : null,
                deliveryReservation: this.updateModel.deliveryReservation ? this.updateModel.deliveryReservation : null,
                deliveryCompany: this.updateModel.deliveryCompany ? Number(this.updateModel.deliveryCompany) : null,
                updateDatetime: this.updateModel.updateDatetime,
              },
            };
          }
          console.log(requestContents);
          const result = await this.$store.dispatch("deficiency/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`不足情報を編集しました (${result.data.contents.deficiency.deficiencyNumber})`, {
                timeout: 2300,
              });
              this.$emit("onEditSubmit", result.data.contents.deficiency);
              this.initUpdateModel(result.data.contents.deficiency);
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `不足編集`,
                text: result.data.header.messages[this.updateModel.deficiencyNumber].join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.invoiceForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validDeficiencyQuantity(value) {
      if (value == null || this.updateModel.deficiencyQuantity == null) return true;
      if (Number(this.updateModel.deficiencyQuantity) > Number(this.updateModel.purchaseQuantity))
        return "不足数が発注数を超えています。";
      return true;
    },
    validReplacementQuantity(value) {
      if (!this.updateModel.replacementExistence) return true;
      let required = Validation.required(value);
      if (required != true) return required;
      if (Number(this.updateModel.replacementQuantity) > Number(this.updateModel.deficiencyQuantity))
        return "補填品数が不足数を超えています。";
      return true;
    },
    validInspectionQuantity(value) {
      if (!this.updateModel.inspectionResult) return true;
      let required = Validation.required(value);
      return required;
    },
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
};
</script>
