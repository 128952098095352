<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="allowedAction(constants.cafereoActions)"
            ><v-icon>mdi-note-search</v-icon>メーカー返品一覧</template
          >
          <template v-else><v-icon>mdi-book-search</v-icon>返品一覧</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日From"
                  dense
                  type="date"
                  v-model="searchModel.createDateFrom"
                  :rules="[rules.createDatetimeFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日To"
                  dense
                  type="date"
                  v-model="searchModel.createDateTo"
                  :rules="[rules.createDatetimeTo]"
                ></dp-date-picker> </v-col
            ></v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser"
                  class="mx-2"
                  v-model="searchModel.jancodes"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.productName"
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                  label="商品名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.maker"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="仕入先略称"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(constants.updateActions)"
          :disabled="!selectedRow || selectedRow.returnCommitStatus == 5"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <purchase-return-entry
            :inputModel="purchaseReturnModel"
            @onDialogClose="onDialogClose"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></purchase-return-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction(constants.cancelActions)"
          :disabled="!rowsSelected || !(activeStatusTab === 'unapproved' || activeStatusTab === 'notshipped')"
          icon="mdi-pencil-remove-outline"
          @click="onBtnCancel"
          >キャンセル</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="allowedAction(constants.csvActions) || allowedAction(constants.supplierActions)"
          icon="mdi-download"
          @click="onBtnExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 8 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-tabs v-model="activeStatusTab" @change="onActiveStatusTabChanged">
          <v-tab href="#unapproved" style="width: 145px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="unapprovedCount"
              :value="unapprovedCount"
              >承認待ち</v-badge
            >
            <template v-if="allowedAction(constants.cafereoActions)">承認待ち</template>
          </v-tab>
          <v-tab href="#notshipped" style="width: 145px">
            <template>出荷待ち</template>
          </v-tab>
          <v-tab href="#uninspected" style="width: 180px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="uninspectedCount"
              :value="uninspectedCount"
              >検品待ち</v-badge
            >
            <template v-if="allowedAction(constants.cafereoActions)">検品待ち</template>
          </v-tab>
          <v-tab href="#inspected" style="width: 180px"> 検品済み </v-tab>
          <v-tab href="#cancel" style="width: 180px"> キャンセル </v-tab>
        </v-tabs>
        <v-row v-if="allowedAction(constants.cafereoActions)">
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                返品数合計：{{ this.returnQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                返品金額合計：￥{{ this.returnAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="allowedAction(constants.supplierActions)">
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                返品金額合計：￥{{ this.returnAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="OrderReturnList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="
            (data) => {
              return data.returnCommitNumber;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="OrderReturnInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 4"
      >
        <v-divider vertical></v-divider>
        <purchase-return-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :isCafereo="allowedAction(constants.cafereoActions)"
          :returnCommitNumber="selectedReturnCommitNumber"
          :purchaseId="selectedRow ? selectedRow.purchaseId : null"
        ></purchase-return-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="approvalConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="承認"
      message="TESTTEST"
      :columns="approvalConfirmColmuns"
      :autoClose="false"
    ></confirm-grid-dialog>
    <confirm-grid-dialog
      ref="inspectedConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="検品結果入力"
      message="TESTTEST"
      :columns="inspectedConfirmColmuns"
      :autoClose="false"
    ></confirm-grid-dialog>
    <bottom-right-attacher>
      <template>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unapproved' && allowedAction(constants.supplierActions)"
          :disabled="!allowedAction(constants.approvalActions) || !rowsSelected"
          @click="onBtnApprovalTab"
        >
          <v-icon>mdi-check</v-icon>承認
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'uninspected' && allowedAction(constants.supplierActions)"
          :disabled="!allowedAction(constants.inspectActions) || !rowsSelected"
          @click="onBtnInspected"
        >
          <v-icon>mdi-bank-check</v-icon>検品結果入力
        </v-btn>
      </template>
    </bottom-right-attacher>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import BottomRightAttacher from "../../components/common/BottomRightAttacher.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import PurchaseReturnInfos from "./../../components/purchaseReturn/PurchaseReturnInfos.vue";
import PurchaseReturnEntry from "./../../components/purchaseReturn/PurchaseReturnEntry.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { BooleanCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import productConditionTypes from "../../consts/productConditionTypes";
import CafereoPurchaseReturnStatuses from "../../consts/purchaseReturnStatus/CafereoPurchaseReturnStatuses";
import VendorPurchaseReturnStatuses from "../../consts/purchaseReturnStatus/VendorPurchaseReturnStatuses";
import { SelectionFilter } from "../../components/ag-grid/filters";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import RequestUtils from "./../../utils/RequestUtils";

export default {
  name: "PurchaseReturnSearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      // 権限グループ
      constants: {
        cafereoActions: ["C030501"],
        updateActions: ["C030502"],
        cancelActions: ["C030503"],
        csvActions: ["C030504"],
        supplierActions: ["M020501", "M020502", "M020503", "M020504"],
        approvalActions: ["M020502"],
        inspectActions: ["M020503"],
      },
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isMinNumber: Validation.isMinNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        createDatetimeFrom: (value) => this.createDatetimeFromRules(value),
        createDatetimeTo: (value) => this.createDatetimeToRules(value),
      },
      returnQuantity: 0,
      returnAmount: 0,
      validSeearchForm: null,
      activeStatusTab: null,
      statedReturn: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      infoMaximum: false,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchModel: {
        createDatetimeFrom: "",
        createDatetimeTo: "",
        jancodes: "",
        jancode: null,
        productName: "",
        maker: "",
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
      },
      purchaseReturnModel: {
        createNew: false,
      },
      editDialog: { show: false, width: "1800px" },
      divideDialog: false,
      selectionRows: null,
      cafereoColumnDefs: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "returnCommitStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoPurchaseReturnStatuses.all() },
          valueGetter: (params) => CafereoPurchaseReturnStatuses.of(params.data.returnCommitStatus),
        },
        { headerName: "返品確認番号", field: "returnCommitNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "商品状態区分",
          colId: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "出荷元", field: "warehouseName" },
        { headerName: "仕入先略称", field: "maker" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品単価合計",
          field: "unitReturnPriceTotal",
          type: "dpNumericColumn",
        },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: "届先名称", field: "delivery2", filterParams: IncludeFilter },

        { headerName: "出荷予定日", field: "shipPlanDate", type: "dpDateColumn" },
        {
          headerName: "検品数",
          field: "makerInspectionQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "メーカー承認日時", field: "makerApprovalDatetime", type: "dpDateColumn" },
        { headerName: "メーカー承認者", field: "makerApprovalUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      makerColumnDefs: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "returnCommitStatus",
          filterParams: { options: VendorPurchaseReturnStatuses.all() },
          valueGetter: (params) => VendorPurchaseReturnStatuses.of(params.data.returnCommitStatus),
        },
        { headerName: "返品確認番号", field: "returnCommitNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "商品状態区分",
          colId: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品単価合計",
          field: "unitReturnPriceTotal",
          type: "dpNumericColumn",
        },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: "届先名称", field: "delivery2", filterParams: IncludeFilter },
      ],
      approvalConfirmColmuns: [
        {
          headerName: "承認",
          field: "doApproval",
          pinned: "left",
          cellRenderer: BooleanCellRenderer("mdi-check-circle", "mdi-close"),
        },
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "returnCommitStatus",
          filterParams: { options: VendorPurchaseReturnStatuses.all() },
          valueGetter: (params) => VendorPurchaseReturnStatuses.of(params.data.returnCommitStatus),
        },
        { headerName: "返品確認番号", field: "returnCommitNumber", pinned: "left" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "商品状態区分",
          colId: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品単価合計",
          field: "unitReturnPriceTotal",
          type: "dpNumericColumn",
        },
      ],
      inspectedConfirmColmuns: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "returnCommitStatus",
          filterParams: { options: VendorPurchaseReturnStatuses.all() },
          valueGetter: (params) => VendorPurchaseReturnStatuses.of(params.data.returnCommitStatus),
        },
        { headerName: "返品確認番号", field: "returnCommitNumber", pinned: "left" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "商品状態区分",
          colId: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "返品単価合計",
          field: "unitReturnPriceTotal",
          type: "dpNumericColumn",
        },
        {
          headerName: "検品数",
          field: "makerInspectionQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
        },
      ],
      errorColmuns: [
        { headerName: "返品確認番号", field: "returnCommitNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  components: {
    AgGridVue,
    PurchaseReturnInfos,
    PurchaseReturnEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    BottomRightAttacher,
    ErrorGridDialog,
  },
  watch: {
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.onPageSizeChanged();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 - 48 + "px";
    },
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.gridStyle.height = this.gridHeightSize - 96.5 - 48 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    unapprovedCount() {
      return this.statedReturn && this.statedReturn.unapproved ? this.statedReturn.unapproved.length : null;
    },
    uninspectedCount() {
      return this.statedReturn && this.statedReturn.uninspected ? this.statedReturn.uninspected.length : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          return !column.colDef.hide;
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          if (
            coldef.field == "createDatetime" ||
            coldef.field == "updateDatetime" ||
            coldef.field == "makerApprovalDatetime"
          ) {
            coldef.type = "dpFullDateColumn";
          }
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.returnCommitNumber, detailColumns, this.gridApi);
    },
    selectedReturnCommitNumber() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.returnCommitNumber;
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onActiveStatusTabChanged(activeTab) {
      if (this.gridApi && this.statedReturn) {
        this.gridApi.setRowData(this.statedReturn[activeTab] || []);

        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);

        this.returnQuantity = 0;
        this.returnAmount = 0;
        for (let row of this.statedReturn[this.activeStatusTab] || []) {
          if (row.returnQuantity) {
            this.returnQuantity += row.returnQuantity;
          }
          if (row.unitReturnPriceTotal) {
            this.returnAmount += row.unitReturnPriceTotal;
          }
        }
      }
      this.selectedRow = null;
      this.rowsSelected = false;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = this.allowedAction(this.constants.supplierActions)
        ? "返品一覧.csv"
        : "メーカー返品一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      this.selectionRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onBtnUpdate() {
      this.purchaseReturnModel = {
        ...this.selectedRow,
      };
      this.editDialog.show = true;
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEditSubmit(updateModel) {
      if (this.activeStatusTab == "unapproved" || this.activeStatusTab == "inspected") {
        this.gridApi.applyTransaction({ update: [updateModel] });
        this.statedReturn[this.activeStatusTab] = this.statedReturn[this.activeStatusTab].map((stateData) => {
          if (stateData.returnCommitNumber == updateModel.returnCommitNumber) return updateModel;
          return stateData;
        });
      } else {
        const beforeList = [];
        beforeList.push(...this.statedReturn[this.activeStatusTab]);
        this.statedReturn[this.activeStatusTab] = [];
        for (let record of beforeList) {
          if (record.purchaseId == updateModel.purchaseId) {
            RequestUtils.pushPurchaseReturn(updateModel, this.statedReturn);
          } else {
            RequestUtils.pushPurchaseReturn(record, this.statedReturn);
          }
        }
        if (
          this.statedReturn[this.activeStatusTab].some(
            (elm) => elm.returnCommitNumber == updateModel.returnCommitNumber
          )
        ) {
          this.gridApi.applyTransaction({ update: [updateModel] });
        } else {
          this.gridApi.applyTransaction({ remove: [updateModel] });
        }
        this.selectedRow = null;
      }
      this.selectedRow = updateModel;
      this.editDialog.show = false;
    },
    // キャンセル処理
    async onBtnCancel() {
      let messageText = `選択された返品をキャンセルします (${this.gridApi.getSelectedRows().length}件)`;
      let title = this.allowedAction(this.constants.cafereoActions) ? "メーカー返品一覧" : "返品一覧";
      const ok = await this.$dialog.confirm({ title: title, text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionRows.forEach((row) => {
            updateModels.push({
              returnCommitNumber: row.returnCommitNumber,
              lastUpdateDatetime: row.updateDatetime,
            });
          });
          console.log(updateModels);
          var requestContents = {
            cancelConditions: updateModels,
          };

          const result = await this.$store.dispatch("purchaseReturn/cancel", requestContents);

          let error = result.data?.header;

          const afterRows = [];
          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    returnCommitNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              this.statedReturn[this.activeStatusTab].forEach((statusData) => {
                let diffFlg = false;
                result.data.contents.purchaseReturns.forEach((purchaseReturn) => {
                  if (purchaseReturn.returnCommitNumber == statusData.returnCommitNumber) {
                    statusData.returnCommitStatus = purchaseReturn.returnCommitStatus;
                    statusData.updateDatetime = purchaseReturn.updateDatetime;
                    this.statedReturn.cancel.push(statusData);
                    removeRow.push(statusData);
                    diffFlg = true;
                  }
                });
                if (!diffFlg) afterRows.push(statusData);
              });
              this.statedReturn[this.activeStatusTab] = afterRows;
              if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "メーカー返品キャンセル", records: errorRows });
                this.$dialog.notify.error(`メーカー返品キャンセルに失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`メーカー返品がキャンセルされました`, { timeout: 2300 });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseReturnSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    // 検索ボタン押下処理
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    // 検索処理
    async onSearchClick() {
      try {
        this.loadingOn();
        // jancodeのリスト化
        this.searchModel.janCode = this.searchModel.jancodes
          .replaceAll(/\n+/g, " ")
          .split(" ")
          .filter((n) => n);
        const response = await this.$store.dispatch("purchaseReturn/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "受注管理" : "発注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.purchaseReturns).length === 0) {
          let title = this.allowedAction(this.constants.supplierActions) ? "受注管理" : "発注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.statedReturn = result.statedReturns || {};
        this.gridApi.setRowData(this.statedReturn[this.activeStatusTab] || []);

        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);

        this.returnQuantity = 0;
        this.returnAmount = 0;
        for (let row of this.statedReturn[this.activeStatusTab] || []) {
          if (row.returnQuantity) {
            this.returnQuantity += row.returnQuantity;
          }
          if (row.unitReturnPriceTotal) {
            this.returnAmount += row.unitReturnPriceTotal;
          }
        }
      } catch (error) {
        console.error("PurchaseReturnSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onfilterChanged() {
      this.returnQuantity = 0;
      this.returnAmount = 0;
      this.gridApi.forEachNodeAfterFilter((row) => {
        if (row.displayed) {
          if (row.data.returnQuantity) {
            this.returnQuantity += row.data.returnQuantity;
          }
          if (row.data.unitReturnPriceTotal) {
            this.returnAmount += row.data.unitReturnPriceTotal;
          }
        }
      });
    },
    onPageSizeChanged() {
      if (this.gridApi) this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    // 承認
    onBtnApprovalTab() {
      const rows = [];
      this.gridApi.forEachNode((node) => {
        if (node.selected) {
          rows.push({
            doApproval: node.selected,
            ...node.data,
          });
        }
      });
      this.$refs.approvalConfirmGrid.open({
        records: rows,
        subtitle: `全: ${rows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            rows.forEach((row) => {
              updateModels.push({
                returnCommitNumber: row.returnCommitNumber,
                approval: row.doApproval,
                updateDatetime: row.updateDatetime,
              });
            });
            var requestContents = {
              returnApprovalConditions: updateModels,
            };

            const result = await this.$store.dispatch("purchaseReturn/approval", requestContents);

            let error = result.data?.header;

            const afterRows = [];
            const removeRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      returnCommitNumber: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                if (result.data?.contents?.purchaseReturns) {
                  this.statedReturn[this.activeStatusTab].forEach((statusData) => {
                    let diffFlg = false;
                    result.data.contents.purchaseReturns.forEach((purchaseReturn) => {
                      if (purchaseReturn.returnCommitNumber == statusData.returnCommitNumber) {
                        statusData.returnCommitStatus = purchaseReturn.returnCommitStatus;
                        statusData.updateDatetime = purchaseReturn.updateDatetime;
                        this.statedReturn.notshipped.push(statusData);
                        removeRow.push(statusData);
                        diffFlg = true;
                      }
                    });
                    if (!diffFlg) afterRows.push(statusData);
                  });
                  this.statedReturn[this.activeStatusTab] = afterRows;
                }
                if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                if (errorRows.length > 0) {
                  this.$refs.bulkErrorGrid.open({ title: "返品承認", records: errorRows });
                  this.$dialog.notify.error(`返品承認に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`返品を承認しました`, { timeout: 2300 });
                  this.$refs.approvalConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PurchaseReturnSearch::onBtnApprovalTab", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 検品結果入力
    onBtnInspected() {
      const rows = [];
      this.gridApi.getSelectedRows().forEach((node) => {
        rows.push({
          ...node,
          makerInspectionQuantity: node.returnQuantity,
        });
      });
      this.$refs.inspectedConfirmGrid.open({
        records: rows,
        onSubmit: async (records) => {
          if (this.validateRows(records)) {
            try {
              this.loadingOn();
              let updateModels = [];
              records.forEach((row) => {
                updateModels.push({
                  returnCommitNumber: row.returnCommitNumber,
                  makerInspectionQuantity: Number(row.makerInspectionQuantity),
                  updateDatetime: row.updateDatetime,
                });
              });
              var requestContents = {
                returnInspectionResults: updateModels,
              };

              const result = await this.$store.dispatch("purchaseReturn/result", requestContents);

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        returnCommitNumber: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.purchaseReturns) {
                    this.statedReturn[this.activeStatusTab].forEach((statusData) => {
                      let diffFlg = false;
                      result.data.contents.purchaseReturns.forEach((purchaseReturn) => {
                        if (purchaseReturn.returnCommitNumber == statusData.returnCommitNumber) {
                          statusData.returnCommitStatus = purchaseReturn.returnCommitStatus;
                          statusData.updateDatetime = purchaseReturn.updateDatetime;
                          this.statedReturn.inspected.push(statusData);
                          removeRow.push(statusData);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(statusData);
                    });
                  }
                  this.statedReturn[this.activeStatusTab] = afterRows;
                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    this.$refs.bulkErrorGrid.open({ title: "検品結果更新", records: errorRows });
                    this.$dialog.notify.error(`検品結果更新に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`検品結果を更新しました`, { timeout: 2300 });
                    this.$refs.inspectedConfirmGrid.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } catch (error) {
              console.error("PurchaseReturnSearch::onBtnApprovalTab", error);
              this.apiRequestError(error);
            } finally {
              this.loadingOff();
            }
          }
        },
      });
    },
    createDatetimeFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.createDateTo)) return "登録日Toより前の日付を指定してください";
      return true;
    },
    createDatetimeToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.createDateFrom)) return "登録日Fromより後の日付を指定してください";
      return true;
    },
    validateRows(rows) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row) => {
        var error = this.onValidResult(row);
        if (error != true) {
          errorRows.push({
            returnCommitNumber: row.returnCommitNumber,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: "検品結果更新", records: errorRows });
        this.$dialog.notify.error(`入力データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidResult(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      // 検品数
      columnName = "検品結果";
      checkValue = row.makerInspectionQuantity;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNumber(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(8)(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isMinNumber(0)(checkValue), columnName, messages);
      this.setValidMessage(this.inspectionQuantityRules(row), columnName, messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    inspectionQuantityRules(row) {
      if (Number(row.makerInspectionQuantity) > Number(row.returnQuantity)) return "返品数を超えています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];
    if (this.allowedAction(this.constants.cafereoActions))
      this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
    if (this.allowedAction(this.constants.supplierActions))
      this.columnDefs = this.columnDefs.concat(this.makerColumnDefs);

    this.rowData = [];
  },
};
</script>
