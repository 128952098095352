<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-col class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</v-col>
            <div style="height: 300px">
              <ag-grid-vue
                :alwaysShowHorizontalScroll="true"
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{
                  importNumber
                }})
              </p>
              <p v-if="errorRows.length > 0">発注情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="発注一括取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import { NumericColumn, PercentColumn } from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "PurchaseImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
      },
    },
    cafereoColumns: [
      { headerName: "行番号", field: "rowNumber" },
      { headerName: "仕入先略称", field: "supplierShortName" },
      { headerName: "JANCODE", field: "jancode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "発注数量", field: "purchaseQuantity", type: "dpNumericColumn" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
      { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
    ],
    csvRecords: [],
    importNumber: null,
    importFileFomat: { headerCnt: 1, details: [{ fieldName: "jancode" }, { fieldName: "purchaseQuantity" }] },
    importHelper: null,
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxLength: Validation.maxLength,
      isMinNumber: Validation.isMinNumber,
      isCsv: Validation.isCsv,
      maxRateLength: Validation.maxRateLength,
      isJancode: Validation.isJancode,
    },
    importCount: 0,
    errorColmuns: [
      { field: "rowNumber", headerName: "行番号" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        this.loadingOn();
        let formatError = false;
        let lineCnt = 0;
        try {
          this.loginUserCorporateCd = this.$store.getters["security/loggedInUser"].corporateCode;
          var csvLines = [];
          csvLines = await FileUtils.readAsCsv(this.file, true);
          this.csvRecords = [];
          this.conversionCode = "";
          // 先頭１行目はヘッダーのため削除
          csvLines.splice(0, this.importHelper.headerCnt);
          lineCnt = this.importHelper.headerCnt;
          for (const line of csvLines) {
            lineCnt++;
            console.log("csvLines:" + csvLines.length + " lineCnt:" + lineCnt + " line.length:" + line.length);
            if (line.length == this.importHelper.details.length) {
              var csvRecord = await this.convertFormatData(line);
              csvRecord.rowNumber = this.csvRecords.length + 1;
              this.csvRecords.push(csvRecord);
            } else if (csvLines.length == lineCnt - 1 && line.length == 1) {
              // 最終行の空行は無視
              console.log("line:" + line);
            } else {
              formatError = true;
              throw new Error();
            }
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          let addMessage = "";
          if (formatError) {
            addMessage = "（ファイルフォーマットエラー：" + lineCnt + "行目）";
          }
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。${addMessage}`, { timeout: 2300 });
          console.log(error);
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.errorRows = [];
          this.loadingOn();
          var param = { purchases: requestRecords };
          const response = await this.$store.dispatch("purchase/import", param);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importNumber = response.data.contents.importNumber;
              this.importCount = response.data.contents.purchaseCount;
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.importNumber = response.data.contents.purchaseCount > 0 ? response.data.contents.importNumber : null;
              this.importCount = response.data.contents.purchaseCount;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // JANCODE
      this.setValidMessage(this.rules.required(row.jancode), "JANCODE", messages);
      this.setValidMessage(this.rules.isNumber(row.jancode), "JANCODE", messages);
      this.setValidMessage(this.rules.isJancode(row.jancode), "JANCODE", messages);
      // 発注数
      this.setValidMessage(this.rules.required(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.isNumber(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(
        row.unit > row.purchaseQuantity ? "発注単位以上を指定してください。" : true,
        "発注数",
        messages
      );

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        jancode: this.stringFormat(row.jancode),
        purchaseQuantity: this.numberFormat(row.purchaseQuantity),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    async convertFormatData(line) {
      //
      var ret = {
        rowNumber: 0,
        jancode: "",
        supplierShortName: null,
        productName: "",
        title: "",
        retailPrice: "",
        purchaseRate: null,
        purchaseQuantity: line[this.importHelper.findIndexByFieldName("purchaseQuantity")],
        purchasePriceTotal: 0,
        unit: 0,
      };
      if (line[this.importHelper.findIndexByFieldName("jancode")]) {
        let productResponse = await this.$store.dispatch("product/search", {
          janCode: line[0],
        });
        switch (productResponse.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            if (productResponse.data.contents.products.length > 0) {
              const product = productResponse.data.contents.products[0];
              ret.productCd = product.productCd;
              ret.jancode = product.jancode;
              ret.supplierShortName = product.supplierShortName;
              ret.productName = product.productName;
              ret.title = product.title;
              ret.retailPrice = product.retailPrice;
              ret.purchaseRate = product.purchaseRate;
              ret.purchasePriceTotal = product.purchasePrice * Number(ret.purchaseQuantity);
              ret.unit = product.unit;
            }
            break;
          default:
            this.redirectError();
            break;
        }
      }
      return ret;
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = this.cafereoColumns;
    this.importHelper = FileUtils.importHelper(this.importFileFomat);
  },
};
</script>
