<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>発注登録</span>
      <span class="headline" v-if="!inputModel.createNew"
        ><v-icon>mdi-pencil-outline</v-icon>{{ allowedAction(constants.cafereoActions) ? "発注" : "受注" }}編集</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="purchaseForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle>商品情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="JANCODE"
                filled
                dense
                v-model="updateModel.janCode"
                :rules="[rules.required, rules.isJancode, rules.checkJancode]"
                @change="onSearchProduct()"
                :disabled="!inputModel.createNew"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="タイトル" filled dense disabled v-model="updateModel.title"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" filled dense disabled v-model="updateModel.productName"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field
                label="発注締日"
                filled
                dense
                disabled
                v-model="updateModel.orderClosingDate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field label="発売日" filled dense disabled v-model="updateModel.releaseDate"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field
                label="Box入数"
                filled
                dense
                disabled
                @change="updateModel.inBoxNumber = $event !== '' ? $event : null"
                v-model="updateModel.inBoxNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field label="上限数" filled dense disabled v-model="updateModel.maximumQuantity"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field
                label="商品備考（メーカー）"
                filled
                dense
                disabled
                v-model="updateModel.productMakerRemarks"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field
                label="商品備考（カフェレオ）"
                filled
                dense
                disabled
                v-model="updateModel.productRemarks"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">発注情報</v-card-subtitle>
        <v-card-subtitle v-if="allowedAction(constants.supplierActions)">受注情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense v-if="allowedAction(constants.supplierActions)">
            <v-col cols="12" sm="3">
              <v-select
                :items="purchaseStatuses"
                label="ステータス"
                dense
                filled
                disabled
                v-model="updateModel.purchaseStatus"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="発注ID" filled dense disabled v-model="updateModel.purchaseId"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                :items="purchaseTypeList"
                label="発注区分"
                dense
                filled
                :disabled="allowedAction(constants.supplierActions)"
                v-model="updateModel.purchaseType"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="発注日" filled dense disabled v-model="updateModel.purchaseOrderDate"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.supplierActions)">
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="about"
                label="納品予定日"
                v-model="updateModel.deliveryTime"
                :hideAbout="hideAbout"
                :rules="[rules.validRequiredDeliveryTime]"
                :disabled="!(updateModel.purchaseStatus == 3 || updateModel.purchaseStatus == 4)"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="date"
                label="納品日"
                :inteval="5"
                v-model="updateModel.deliveryReservation"
                :rules="[
                  rules.validRequiredDeliveryReservation,
                  rules.isNextdayAfter,
                  rules.validShipHourDeliveryReservation,
                ]"
                :disabled="!(updateModel.purchaseStatus == 4 || updateModel.purchaseStatus == 5)"
              ></dp-date-picker>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.supplierActions)">
            <v-col cols="12" sm="1">
              <v-text-field
                label="上代"
                filled
                dense
                @change="updateModel.retailPrice = $event !== '' ? $event : null"
                v-model="updateModel.retailPrice"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="発注数"
                filled
                dense
                :rules="[
                  rules.required,
                  rules.maxLength(8),
                  rules.isNumber,
                  rules.isMinNumber(1),
                  rules.validPurchaseQuantity,
                ]"
                v-model="updateModel.purchaseQuantity"
                @change="onCalcPrice()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="仕入掛率"
                filled
                dense
                @change="updateModel.purchaseRate = $event !== '' ? $event : null"
                disabled
                v-model="updateModel.purchaseRate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="販売単価"
                filled
                dense
                @change="updateModel.unitPrice = $event !== '' ? $event : null"
                v-model="updateModel.unitPrice"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="販売合計金額"
                filled
                dense
                @change="updateModel.purchasePriceTotal = $event !== '' ? $event : null"
                disabled
                v-model="updateModel.purchasePriceTotal"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="4">
              <v-text-field label="メーカー名" filled dense disabled v-model="updateModel.maker"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="1">
              <v-text-field
                label="発注数"
                filled
                dense
                :rules="[
                  rules.required,
                  rules.maxLength(8),
                  rules.isNumber,
                  rules.isMinNumber(1),
                  rules.validPurchaseQuantity,
                ]"
                v-model="updateModel.purchaseQuantity"
                @change="onCalcPrice()"
                :disabled="updateModel.purchaseStatus == 8"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="発注数(pcs)"
                filled
                dense
                @change="updateModel.purchasePcsQuantity = $event !== '' ? $event : null"
                disabled
                v-model="updateModel.purchasePcsQuantity"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="上代"
                filled
                dense
                @change="updateModel.retailPrice = $event !== '' ? $event : null"
                v-model="updateModel.retailPrice"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="仕入掛率"
                filled
                dense
                @change="updateModel.purchaseRate = $event !== '' ? $event : null"
                disabled
                v-model="updateModel.purchaseRate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="仕入価格"
                filled
                dense
                v-model="updateModel.unitPrice"
                @change="onCalcPrice()"
                :disabled="
                  (updateModel.purchaseType == 2 || updateModel.purchaseType == 3) && updateModel.unitPrice === 0
                "
                :rules="[
                  rules.required,
                  rules.maxLength(8),
                  rules.isNumber,
                  rules.isMinNumber(0),
                  rules.validPurchasePrice,
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="仕入合計金額"
                filled
                dense
                @change="updateModel.purchasePriceTotal = $event !== '' ? $event : null"
                disabled
                v-model="updateModel.purchasePriceTotal"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew">
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="about"
                label="納品予定日"
                v-model="updateModel.deliveryTime"
                :hideAbout="hideAbout"
                :rules="[rules.validRequiredDeliveryTime]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="date"
                label="納品予約"
                :inteval="5"
                v-model="updateModel.deliveryReservation"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="deliveryCompanys"
                label="配送業者"
                dense
                filled
                v-model="updateModel.deliveryCompany"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="12" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field
                label="備考(メーカー表示)"
                filled
                dense
                v-model="updateModel.purchaseRemarks"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew"
          >配送先情報</v-card-subtitle
        >
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew">
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="配送コード" filled dense disabled v-model="updateModel.deliveryCode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="配送先郵便番号"
                filled
                dense
                disabled
                v-model="updateModel.deliveryPostalCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-text-field
                label="配送先住所"
                filled
                dense
                disabled
                v-model="updateModel.deliveryAddress"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="届先法人名" filled dense disabled v-model="updateModel.deliveryName1"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label=" 届先名称" filled dense disabled v-model="updateModel.deliveryName2"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="配送先TEL"
                filled
                dense
                disabled
                v-model="updateModel.deliveryPhonenumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">付加情報</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="3">
              <cafereo-user-field
                ref="purchasePerson"
                label="Café Reo担当者"
                :value="updateModel.purchasePerson"
                v-model="updateModel.purchasePerson"
                :rules="[rules.required]"
              ></cafereo-user-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="メーカー担当者"
                filled
                dense
                v-model="updateModel.makerPerson"
                :rules="[rules.required, rules.maxLength(100)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field label="社内備考1" filled dense v-model="updateModel.cafereoRemarks"></v-text-field>
            </v-col>
          </v-row>
          <v-divider v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew"></v-divider>
        </v-container>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew"
          >付加情報</v-card-subtitle
        >
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions) && !inputModel.createNew">
          <ag-grid-vue
            id="orderList"
            :domLayout="domLayout"
            class="ag-theme-alpine"
            :defaultColDef="orderDefaultColDef"
            :columnDefs="orderColumnDefs"
            :rowData="orderRowData"
            :alwaysShowHorizontalScroll="true"
            :pagination="true"
            :paginationPageSize="orderSelectedPageSize"
            :suppressCsvExport="false"
            :suppressExcelExport="false"
            :defaultCsvExportParams="false"
            :localeText="localeText"
            :enableCellTextSelection="true"
            rowSelection="single"
            :columnTypes="columnTypes"
            @grid-ready="onOrderGridReady"
            :frameworkComponents="frameworkComponents"
          >
          </ag-grid-vue>
        </v-container>
        <v-divider v-if="inputModel.createNew"></v-divider>
        <v-card-subtitle style="display: flex; height: 40px" v-if="inputModel.createNew">
          <span>発注一覧</span>
          <v-spacer></v-spacer>
          <tooltip-icon-button :disabled="!rowsSelected" icon="mdi-content-copy" @click="onBtnCopy"
            >コピー</tooltip-icon-button
          >
          <tooltip-icon-button :disabled="!rowsSelected" icon="mdi-pencil" @click="onBtnUpdate"
            >編集</tooltip-icon-button
          >
          <tooltip-icon-button :disabled="!rowsSelected" icon="mdi-trash-can-outline" @click="onBtnDelete"
            >削除</tooltip-icon-button
          >
        </v-card-subtitle>
        <v-container style="max-width: inherit" v-if="inputModel.createNew">
          <ag-grid-vue
            id="CartList"
            :domLayout="domLayout"
            class="ag-theme-alpine"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :alwaysShowHorizontalScroll="true"
            :pagination="true"
            :paginationPageSize="selectedPageSize"
            :suppressCsvExport="false"
            :suppressExcelExport="true"
            :defaultCsvExportParams="defaultCsvExportParams"
            :localeText="localeText"
            :enableCellTextSelection="true"
            :columnTypes="columnTypes"
            rowSelection="single"
            :getRowNodeId="(data) => data.purchaseId"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick()">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onListAdd()" v-if="inputModel.createNew">{{ listAddLabel }}</v-btn>
      <v-btn color="primary" @click="onEntryClick()" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick()" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="bulkErrorGrid"
      width="80%"
      height="80%"
      title="発注登録"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import { BtnCellRenderer } from "../../components/ag-grid/cellRenderers";
import MakerPurchaseStatuses from "../../consts/purchaseStatus/MakerPurchaseStatuses";
import PurchaseTypes from "../../consts/PurchaseTypes";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import ShipHour from "../../consts/ShipHour";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import ProductTypes from "../../consts/ProductTypes";
import OrderTypes from "../../consts/OrderTypes";
import ProductOrderTypes from "../../consts/ProductOrderTypes";
import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "PurchaseEntryForm",
  props: ["inputModel"],
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C030303", "C030302"],
        supplierActions: ["M020205"],
      },
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDecimal: Validation.isDecimal,
        isJancode: Validation.isJancode,
        isMinNumber: Validation.isMinNumber,
        isNextdayAfter: Validation.isNextdayAfter,
        validRequiredDeliveryTime: (value) => this.validRequiredDeliveryTime(value),
        validRequiredDeliveryReservation: (value) => this.validRequiredDeliveryReservation(value),
        validShipHourDeliveryReservation: (value) => this.validShipHourDeliveryReservation(value),
        validPurchasePrice: (value) => this.validPurchasePrice(value),
        validPurchaseQuantity: (value) => this.validPurchaseQuantity(value),
        checkJancode: (value) => this.checkJancode(value),
      },
      redrawFlg: false,
      hideAbout: ["pending"],
      validEntryForm: null,
      columnDefs: null,
      updateModel: {},
      defaultModel: {
        purchaseId: null,
        purchaseType: null,
        purchaseOrderDate: null,
        productCode: null,
        productName: null,
        janCode: null,
        orderClosingDate: null,
        releaseDate: null,
        unit: null,
        inBoxNumber: null,
        maximumQuantity: null,
        productMakerRemarks: null,
        productRemarks: null,
        retailPrice: 0,
        purchaseRate: 0,
        unitPrice: 0,
        purchaseQuantity: 0,
        purchasePcsQuantity: 0,
        purchasePriceTotal: 0,
        paymentSite: null,
        directDeliveryRequest: null,
        deliveryName1: null,
        purchaseStatus: null,
        purchasePerson: null,
        makerCode: null,
        maker: null,
        makerPerson: null,
        deliveryTime: null,
        deliveryReservation: null,
        purchaseRemarks: null,
        remainingQuantity: null,
        cafereoRemarks: null,
        directionNumber: null,
        orderNumber: null,
        dedicatedOrder: null,
        directDeliveryLocalApproval: null,
        directDeliveryRequestDate: null,
        directDeliveryApproval: null,
        directDeliveryResponsePerson: null,
        directDeliveryResponseDate: null,
        title: null,
      },
      rowData: null,
      listAddLabel: "一覧に追加",
      createNew: true,
      defaultColDef: null,
      gridApi: null,
      columnApi: null,
      defaultCsvExportParams: null,
      detailSelected: 0,
      rowsSelected: null,
      purchaseStatuses: MakerPurchaseStatuses.all(),
      purchaseTypeList: PurchaseTypes.all(),
      deliveryCompanys: DeliveryCompanys.all(),
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      editDialog: false,
      orderRowData: null,
      orderColumnDefs: null,
      orderDefaultColDef: null,
      orderGridApi: null,
      orderColumnApi: null,
      orderSelectedPageSize: null,
      frameworkComponents: {
        BtnCellRenderer,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      purchaseColums: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        {
          headerName: "仮発注ID",
          field: "purchaseId",
          pinned: "left",
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "発注締日", field: "orderClosingDate" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "Box入数", field: "inBoxNumber" },
        { headerName: "商品備考（メーカー）", field: "productMakerRemarks" },
        { headerName: "商品備考（Cafereo）", field: "productRemarks" },
        { headerName: "メーカー名", field: "maker" },
        {
          headerName: "発注数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "発注数(psc)",
          field: "purchasePcsQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕入価格",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入合計金額",
          field: "purchasePriceTotal",
          type: "dpNumericColumn",
        },
        { headerName: "備考(メーカー表示)", field: "purchaseRemarks" },
        { headerName: "Café Reo担当者", field: "purchasePersonName" },
        { headerName: "メーカー担当者", field: "makerPerson" },
        { headerName: "社内備考1", field: "cafereoRemarks" },
      ],
      errorColmuns: [
        { headerName: "行番号", field: "rowIndex" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      loading: false,
    };
  },
  components: {
    AgGridVue,
    TooltipIconButton,
    CafereoUserField,
    ErrorGridDialog,
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.orderSelectedPageSize = value;
      this.onPageSizeChanged();
    },
    "updateModel.productCode"() {
      if (this.updateModel.janCode) this.$refs.purchaseForm.validate();
    },
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    // 発注グリッド初期処理
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    // コピーアイコンボタン押下
    onBtnCopy() {
      const copyRow = JSON.parse(JSON.stringify(this.selectedRow));
      copyRow.purchaseId = this.rowData.length + 1;
      this.rowData.push(copyRow);
      this.$dialog.notify.info(`選択行をコピーしました`, {
        timeout: 2300,
      });
      this.clearUpdateData();
    },
    // 編集アイコンボタン押下
    onBtnUpdate() {
      this.updateModel = this.selectedRow;
      this.listAddLabel = "一覧に反映";
      this.createNew = false;
    },
    // 発注一覧部から削除
    async onBtnDelete() {
      const ok = await this.$dialog.confirm({
        title: "発注登録",
        text: `選択行を発注一覧から削除します`,
      });
      if (ok) {
        this.rowData.splice(this.selectedRow.purchaseId - 1, 1);
        var rowIndex = 1;
        this.rowData.forEach((data) => {
          data.purchaseId = rowIndex;
          rowIndex = rowIndex + 1;
        });
        this.selectedRow = null;
        this.gridApi.deselectAll();
        this.gridApi.applyTransaction({ update: this.rowData });
        this.$dialog.notify.info(`選択行を発注一覧か削除しました`, { timeout: 2300 });
      }
    },
    // 発注一覧部に追加
    onListAdd() {
      if (this.validate()) {
        if (this.createNew) {
          this.updateModel.purchaseId = this.rowData.length + 1;
          this.updateModel.purchasePersonName = this.$refs.purchasePerson.current?.userName;
          this.rowData.push(this.updateModel);
          this.$dialog.notify.info(`発注一覧に追加しました`, {
            timeout: 2300,
          });
        } else {
          this.rowData.find((row) => {
            if (row.purchaseId == this.updateModel.purchaseId) {
              this.updateModel.purchasePersonName = this.$refs.purchasePerson.current?.userName;
              row = this.updateModel;
            }
            this.gridApi.applyTransaction({ update: this.rowData });
          });
          this.$dialog.notify.info(`発注一覧を更新しました`, {
            timeout: 2300,
          });
        }

        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);

        this.clearUpdateData();
      }
    },
    // 選択行変更
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length == 1;

      if (selectedRows.length == 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    // 編集部初期化
    clearUpdateData() {
      // 編集部分の値にデフォルト値を設定
      this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
      const loginUserId = this.$store.getters["security/loggedInUserId"];
      this.updateModel.purchasePerson = loginUserId;

      // ボタン名称を変更し、状態を初期状態へ変更
      this.listAddLabel = "一覧に追加";
      this.createNew = true;
      this.selectedRow = null;
      this.gridApi.deselectAll();
    },
    // 編集データ初期化
    async initUpdateModel(model) {
      console.log(model);
      this.redrawFlg = false;
      if (model.createNew) {
        // 新規登録の場合、デフォルト値を設定
        this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
        const loginUserId = this.$store.getters["security/loggedInUserId"];
        this.updateModel.purchasePerson = loginUserId;
      } else {
        // 編集の場合、引数を設定
        this.updateModel = JSON.parse(JSON.stringify(model));
        // バリデーションチェック用に退避
        this.updateModel.orgDeliveryTime = this.updateModel.deliveryTime;

        if (this.allowedAction(this.constants.cafereoActions)) {
          // this.onSearchProduct();
          let orderIdList = { orderIdList: [...this.updateModel.orderIdList], statusSkipFlg: true };
          this.orderRowData = [];

          try {
            let result = await this.$store.dispatch("purchase/orderSearch", orderIdList);
            switch (result.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                if (result.data.contents.orders.length > 0) {
                  this.orderRowData = result.data.contents.orders;
                  this.orderRowData.map((order) => {
                    order.btnDisp = !this.updateModel.directDeliveryRequest && order.directDeliveryRequestFlg;
                  });

                  var allColumnIds = [];
                  if (this.orderColumnApi) {
                    this.orderColumnApi.getAllColumns()?.forEach(function (column) {
                      allColumnIds.push(column.colId);
                    });
                    this.orderColumnApi.autoSizeColumns(allColumnIds);
                  }
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PurchaseEntryForm::initUpdateModel", error);
            this.apiRequestError(error);
          }
        }
      }
      if (this.$refs.purchaseForm) {
        this.$refs.purchaseForm.resetValidation();
      }
    },
    // 受注グリッド初期処理
    onOrderGridReady(params) {
      this.orderGridApi = params.api;
      this.orderColumnApi = params.columnApi;

      // var orderAllColumnIds = [];
      // this.orderColumnApi.getAllColumns().forEach(function (column) {
      //   orderAllColumnIds.push(column.colId);
      // });
      // this.orderColumnApi.autoSizeColumns(orderAllColumnIds);
    },
    // 承認却下ボタン押下処理
    async onBtnApproval(self, flg, data) {
      let messageText = `選択された受注の直送依頼を承認します`;
      const ok = await self.$dialog.confirm({ title: "発注編集", text: messageText });
      if (ok) {
        try {
          self.loadingOnSelf();
          var requestContents = {
            purchase: {
              purchaseId: self.updateModel.purchaseId,
              orderId: data.orderId,
              updateDatetime: self.updateModel.updateDate,
            },
          };
          const result = await self.$store.dispatch("purchase/approvalDirect", requestContents);

          let error = result.data?.header;

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (self.orderRowData.length > 1) self.orderGridApi.applyTransaction({ remove: [data] });
              if (self.orderRowData.length == 1) {
                self.updateModel.directDeliveryRequest = true;
                data.btnDisp = !self.updateModel.directDeliveryRequest && data.directDeliveryRequestFlg;
                self.orderGridApi.applyTransaction({ update: [data] });
              }
              self.updateModel.purchaseQuantity = result.data?.contents.purchase.purchaseQuantity;
              self.updateModel.updateDate = result.data?.contents.purchase.updateDate;
              self.$dialog.notify.info(`選択された受注の直送依頼を承認しました`, { timeout: 2300 });
              self.redrawFlg = true;
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              this.$dialog.warning({
                title: `発注編集`,
                text: error.messages[self.updateModel.purchaseId].join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              self.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          self.loadingOffSelf();
        }
      }
    },
    // ページサイズ変更処理
    onPageSizeChanged() {
      if (this.gridApi) this.gridApi.paginationSetPageSize(this.selectedPageSize);
      if (this.orderGridApi) this.orderGridApi.paginationSetPageSize(this.orderSelectedPageSize);
    },
    // 商品情報検索処理
    async onSearchProduct() {
      if (this.updateModel.janCode >= 8) {
        try {
          let productResponse = await this.$store.dispatch("product/search", {
            jancode: this.updateModel.janCode,
          });
          this.updateModel.productCode = null;
          this.updateModel.title = null;
          this.updateModel.productName = null;
          this.updateModel.orderClosingDate = null;
          this.updateModel.releaseDate = null;
          this.updateModel.inBoxNumber = null;
          this.updateModel.maximumQuantity = null;
          this.updateModel.productRemarks = null;
          this.updateModel.productMakerRemarks = null;
          this.updateModel.makerCode = null;
          this.updateModel.maker = null;
          this.updateModel.purchaseOrderDate = null;
          if (this.inputModel.createNew) {
            this.updateModel.retailPrice = 0;
            this.updateModel.purchaseRate = 0;
            this.updateModel.unitPrice = 0;
            this.updateModel.makerPerson = null;
          }
          this.updateModel.unit = 0;
          switch (productResponse.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (productResponse.data.contents.products.length > 0) {
                const product = productResponse.data.contents.products[0];

                this.updateModel.productCode = product.productCd;
                this.updateModel.title = product.title;
                this.updateModel.productName = product.productName;
                this.updateModel.orderClosingDate = product.orderClosingDate;
                this.updateModel.releaseDate = product.releaseDate;
                this.updateModel.inBoxNumber = product.inBoxQuantity;
                this.updateModel.maximumQuantity = product.maximumQuantity;
                this.updateModel.productRemarks = product.cafereoRemarks;
                this.updateModel.productMakerRemarks = product.makerRemarks;
                this.updateModel.makerCode = product.supplierCd;
                this.updateModel.maker = product.supplierShortName;
                this.updateModel.purchaseOrderDate = product.orderClosingDate;
                if (this.inputModel.createNew) {
                  this.updateModel.retailPrice = product.retailPrice;
                  this.updateModel.purchaseRate = product.purchaseRate;
                  this.updateModel.unitPrice = product.purchasePrice;
                  this.updateModel.makerPerson = product.purchaseStaff;
                }
                this.updateModel.unit = product.unit;
                this.onCalcPrice();
              }
              this.updateModel.purchasePriceTotal = this.updateModel.unitPrice * this.updateModel.purchaseQuantity;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseEntryForm::onSearchProduct", error);
          this.apiRequestError(error);
        }
      }
    },
    // 登録ボタン押下処理
    async onEntryClick() {
      if (this.rowData.length < 1) {
        this.$dialog.error({ text: "発注情報を追加してください", actions: ["OK"] });
        return;
      }
      try {
        this.loadingOnSelf();
        let purchuses = [];
        this.rowData.forEach((row) => {
          let purchase = {
            purchaseType: 1, // 買切固定
            makerCode: row.makerCode,
            productCode: row.productCode,
            retailPrice: Number(row.retailPrice),
            purchaseRate: parseFloat(row.purchaseRate),
            unitPrice: Number(row.unitPrice),
            paymentSite: "",
            directDeliveryRequest: false,
            purchasePerson: row.purchasePerson,
            makerPerson: row.makerPerson,
            deliveryTime: "",
            deliveryReservation: null,
            deliveryCompany: null,
            purchaseRemarks: row.purchaseRemarks,
            remainingQuantity: 0,
            badProductQuantity: 0,
            deficiencyQuantity: 0,
            excessQuantity: 0,
            cafereoRemarks: row.cafereoRemarks,
            orderId: [],
            directDeliveryLocalApproval: false,
            directDeliveryRequestDate: null,
            directDeliveryApproval: false,
            directDeliveryResponsePerson: "",
            directDeliveryResponseDate: null,
            purchaseQuantity: Number(row.purchaseQuantity),
            deliveryCode: "",
          };
          purchuses.push(purchase);
        });
        let requestContents = {
          purchases: purchuses,
        };
        const result = await this.$store.dispatch("purchase/entry", requestContents);

        let error = result.data?.header;
        let errorRows = [];
        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
            this.clearUpdateData();
            this.rowData = [];
            this.$dialog.notify.info(`発注を登録しました`, { timeout: 2300 });
            this.$emit("onEntrySubmit");
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            // エラーメッセージ格納
            if (error.messages) {
              Object.keys(error.messages).forEach((key, index) => {
                errorRows.push({
                  rowIndex: index + 1,
                  errorMessage: error.messages[key],
                });
              });
              let errorRowNumber = [];
              // エラー行の行番号を取得
              Object.keys(error.messages).forEach((key) => {
                errorRowNumber.push(Number(key));
              });
              // エラー行の行データのみ取得
              var newRowData = this.rowData.filter((key) => {
                return errorRowNumber.includes(key.purchaseId);
              });
              // エラー行の行番号を初期化しエラーメッセージに初期化後の行番号を設定
              newRowData.forEach((row, index) => {
                row.purchaseId = index + 1;
              });
              this.rowData = newRowData;
              this.gridApi.setRowData(this.rowData);
            }
            if (error.messages.length != purchuses.length) {
              this.redrawFlg = true;
            }
            this.$refs.bulkErrorGrid.open({ title: "発注登録", records: errorRows });
            this.$dialog.notify.error(`発注登録に失敗したデータが存在します。ご確認ください。`, {
              timeout: 2300,
            });
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("PurchaseEntryForm::onUpdateClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOffSelf();
      }
    },
    // 更新ボタン押下処理
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOnSelf();
          let requestContents = {};
          if (this.allowedAction(this.constants.cafereoActions)) {
            requestContents = {
              purchase: {
                purchaseId: this.updateModel.purchaseId,
                purchaseType: Number(this.updateModel.purchaseType),
                productCode: this.updateModel.productCode,
                retailPrice: this.updateModel.retailPrice.toString(),
                purchaseRate: this.updateModel.purchaseRate.toString(),
                unitPrice: this.updateModel.unitPrice.toString(),
                purchasePerson: this.updateModel.purchasePerson,
                makerPerson: this.updateModel.makerPerson,
                deliveryTime: this.updateModel.deliveryTime,
                deliveryReservation: this.updateModel.deliveryReservation,
                deliveryCompany: this.updateModel.deliveryCompany.toString(),
                purchaseRemarks: this.updateModel.purchaseRemarks,
                cafereoRemarks: this.updateModel.cafereoRemarks,
                purchaseQuantity: Number(this.updateModel.purchaseQuantity),
                updateDatetime: this.updateModel.updateDate,
              },
            };
          } else {
            requestContents = {
              order: {
                purchaseId: this.updateModel.purchaseId,
                purchaseQuantity: Number(this.updateModel.purchaseQuantity),
                deliveryTime: this.updateModel.deliveryTime,
                deliveryReservation: this.updateModel.deliveryReservation,
                updateDatetime: this.updateModel.updateDate,
              },
            };
          }
          const result = await this.$store.dispatch("purchase/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`発注を更新しました`, { timeout: 2300 });
              var submitParam = this.allowedAction(this.constants.cafereoActions)
                ? result.data.contents.purchase
                : result.data.contents.order;
              this.$emit("onEditSubmit", submitParam);
              this.initUpdateModel({ createNew: false, ...submitParam });
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = "";
              Object.keys(result.data?.header.messages).forEach(function (key) {
                message += result.data?.header.messages[key].join("<br>");
              });
              if (message != "") {
                this.$dialog.warning({
                  title: `受注編集`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseEntryForm::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOffSelf();
        }
      }
    },
    loadingOnSelf() {
      this.loading = true;
    },
    loadingOffSelf() {
      this.loading = false;
    },
    // キャンセルボタン押下処理
    onCancelClick() {
      this.rowData = [];
      this.$emit("onDialogClose", this.redrawFlg);
    },
    validate() {
      const isValid = this.$refs.purchaseForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, {
          timeout: 2300,
        });
      }
      if (this.updateModel.deliveryReservation != "") {
        // const reservation = new Date(this.updateModel.deliveryReservation);
        const date = new Date();
        date.setDate(date.getDate() + 1);
        // if (this.updateModel.deliveryReservation != null && reservation)
        // const shipHoue = ShipHour.all();
      }
      return isValid;
    },
    validRequiredDeliveryTime(value) {
      if (this.allowedAction(this.constants.cafereoActions)) {
        // initmodelで退避した項目でチェック
        if (this.updateModel.orgDeliveryTime && !value) {
          return "納品予定日は削除できません";
        }
      } else {
        if (this.updateModel.purchaseStatus == 4) {
          return Validation.required(this.updateModel.deliveryTime);
        }
      }
      return true;
    },
    validRequiredDeliveryReservation() {
      if (this.updateModel.purchaseStatus == 5) {
        return Validation.required(this.updateModel.deliveryReservation);
      }
      return true;
    },
    validShipHourDeliveryReservation() {
      if (this.updateModel.purchaseStatus == 4 || this.updateModel.purchaseStatus == 5) {
        const date = new Date();
        const time = date.getHours();
        date.setDate(date.getDate() + 1);
        const reservation = new Date(this.updateModel.deliveryReservation);
        const shipHoue = ShipHour.all();
        const limitHour = this.updateModel.todayInOutFlg ? shipHoue[1].value : shipHoue[0].value;
        if (
          date.getFullYear() == reservation.getFullYear() &&
          date.getMonth() == reservation.getMonth() &&
          date.getDate() == reservation.getDate()
        ) {
          if (time > limitHour) return "納品予約が設定可能時刻を超えています";
        }
      }
      return true;
    },
    validPurchasePrice() {
      if (Number(this.updateModel.unitPrice) > Number(this.updateModel.retailPrice))
        return "仕入価格が上代を超えています。";
      return true;
    },
    validPurchaseQuantity() {
      if (Number(this.updateModel.purchaseQuantity) % Number(this.updateModel.unit) !== 0)
        return "仕入数が発注単位を満たしていません。";
      return true;
    },
    // 数量、仕入価格変更処理
    onCalcPrice() {
      this.updateModel.purchasePriceTotal = this.updateModel.unitPrice * this.updateModel.purchaseQuantity;
      if (this.updateModel.retailPrice == 0 || this.updateModel.unitPrice == 0) {
        this.updateModel.purchaseRate = 0;
      } else {
        this.updateModel.purchaseRate =
          Math.round((this.updateModel.unitPrice / this.updateModel.retailPrice) * 100 * 10) / 10;
      }
      this.updateModel.purchasePcsQuantity = this.updateModel.inBoxNumber * this.updateModel.purchaseQuantity;
    },
    checkJancode() {
      if (this.updateModel.productCode == null) return "JANCODEがマスタに存在しません";
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultColDef = {
      filter: false,
      resizable: true,
      sortable: false,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = this.purchaseColums;
    this.rowData = [];
    this.orderSelectedPageSize = this.globalPageSize;
    this.orderDefaultColDef = {
      filter: false,
      resizable: true,
      sortable: false,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.orderColumnDefs = [
      { headerName: "受注ID", field: "orderId", pinned: "left" },
      { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn", width: 100 },
      {
        headerName: "",
        field: "btnDisp",
        cellRenderer: "BtnCellRenderer",
        cellRendererParams: {
          name: "承認",
          self: this,
          clicked: function (self, params) {
            self.onBtnApproval(self, true, params.data);
          },
        },
        width: 120,
        pinned: "left",
      },
      { headerName: "指示書NO", field: "directionNumber" },
      {
        headerName: "受注区分",
        field: "orderType",
        filter: "dpSelectionFilter",
        filterParams: { options: OrderTypes.all() },
        valueGetter: (params) => OrderTypes.of(params.data.orderType),
      },
      {
        headerName: "区分",
        field: "productOrderType",
        filter: "dpSelectionFilter",
        filterParams: { options: ProductOrderTypes.all() },
        valueGetter: (params) => ProductOrderTypes.of(params.data.productOrderType),
      },
      {
        headerName: "商品区分",
        field: "productType",
        filter: "dpSelectionFilter",
        filterParams: { options: ProductTypes.all() },
        valueGetter: (params) => ProductTypes.of(params.data.productType),
      },
      {
        headerName: "受注ステータス",
        field: "orderStatus",
        filter: "dpSelectionFilter",
        filterParams: { options: CafereoOrderStatuses.all() },
        valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
      },
      { headerName: "フォーキャスト受注", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
      { headerName: "登録日", field: "orderDate", type: "dpDateColumn" },
      { headerName: "社店CD", field: "customerCode" },
      { headerName: "法人名", field: "corporateName" },
      { headerName: "取引先名", field: "customerName" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "入荷予定日", field: "arrivalPlanDate", type: "dpDateColumn" },
      { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
      { headerName: "受注単位", field: "orderUnit" },
      { headerName: "受注数", field: "orderQuantity" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      {
        headerName: "卸掛率",
        field: "wholesaleRate",
        type: "dpPercentColumn",
        valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
      },
      {
        headerName: "卸単価",
        field: "unitPrice",
        type: "dpNumericColumn",
      },
      { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
      { headerName: "メーカー名", field: "supplierName" },
      {
        headerName: "仕入掛率",
        field: "purchaseRate",
        type: "dpPercentColumn",
        valueGetter: (params) => params.data.purchaseRate.toFixed(1),
      },
      {
        headerName: "仕入単価",
        field: "purchasePrice",
        type: "dpNumericColumn",
      },
      { headerName: "粗利単価", field: "grossProfit", type: "dpNumericColumn" },
      { headerName: "粗利合計金額", field: "grossProfitTotal", type: "dpNumericColumn" },
      { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
      { headerName: "配送CD", field: "deliveryCode" },
      { headerName: "配送先住所", field: "deliveryAddress" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: " 届先名称", field: "deliveryName2" },
      { headerName: "確保倉庫", field: "insureStorageName" },
      { headerName: "伝票番号", field: "slipNumber" },
      { headerName: "伝票備考", field: "slipRemarks" },
      { headerName: "親受注ID", field: "parentOrderId" },
      { headerName: "フォーキャスト割当分", field: "assignedForecastFlg", type: "dpCheckmarkColumn" },
      { headerName: "一括取込番号", field: "importNumber" },
      { headerName: "担当チーム", field: "salesTeam" },
      { headerName: "営業担当", field: "salesStaff" },
      { headerName: "アシスタント", field: "salesAssistant" },
      { headerName: "社内備考", field: "cafereoRemarks" },
      { headerName: "PO番号", field: "poNumber" },
      { headerName: "ハイコン", field: "hycon", type: "dpDateColumn" },
      { headerName: "法人CD", field: "corporateCode", hide: true },
      { headerName: "発注締日", field: "orderCloseDate", hide: true },
      { headerName: "案件番号", field: "propositionNumber", hide: true },
      { headerName: "承認者", field: "approvalUser", hide: true },
      { headerName: "承認日時", field: "approvalDatetime", hide: true },
      { headerName: "掛率訂正承認者", field: "rateCorrectionAuthorizer", hide: true },
      { headerName: "掛率訂正承認日時", field: "rateCorrectionApprovalDate", hide: true },
      { headerName: "販売店承認者", field: "salesAuthorizer", hide: true },
      { headerName: "販売店承認日時", field: "salesApprovalDate", hide: true },
      { headerName: "納品予定日", field: "deliveryPlanDate", hide: true },
      { headerName: "伝票日付", field: "slipDate", hide: true },
      { headerName: "登録者", field: "createUser", hide: true },
      { headerName: "登録日時", field: "createDatetime", hide: true },
      { headerName: "更新者", field: "updateUser", hide: true },
      { headerName: "更新日時", field: "updateDatetime", hide: true },
      { headerName: "備考", field: "remarks", hide: true },
      { headerName: "発注ID", field: "purchaseId", hide: true },
    ];
    this.orderRowData = [];
    this.initUpdateModel(this.inputModel);
  },
};
</script>
