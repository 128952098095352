<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title> <v-icon>mdi-book-search</v-icon>不足一覧 </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch">
            <dp-date-picker
              class="mx-2"
              label="登録日From"
              dense
              type="date"
              v-model="searchModel.createDateFrom"
              :rules="[rules.createDateFrom]"
            ></dp-date-picker>
            <dp-date-picker
              class="mx-2"
              label="登録日To"
              dense
              type="date"
              v-model="searchModel.createDateTo"
              :rules="[rules.createDateTo]"
            ></dp-date-picker>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!selectedRow || activeStatusTab == 'notshipped' || activeStatusTab == 'cancel'"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <deficiency-entry
            ref="deficiencyEntry"
            :inputModel="editModel"
            @onDialogClose="onDeficiencyDialogClose"
            @onEditSubmit="onDeficiencyEditSubmit"
            v-if="editDialog.show"
          ></deficiency-entry>
        </v-dialog>
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport">CSVダウンロード</tooltip-icon-button>
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-tabs v-model="activeStatusTab" @change="onActiveStatusTabChanged">
          <v-tab href="#unanswered" style="width: 145px">
            <v-badge offset-x="-1" :content="unansweredCount" :value="unansweredCount">回答待ち</v-badge>
          </v-tab>
          <v-tab href="#unapproved" style="width: 180px">
            <template>承認待ち</template>
          </v-tab>
          <v-tab href="#unreserved" style="width: 180px">
            <v-badge offset-x="-1" :content="unreservedCount" :value="unreservedCount">納品予約待ち</v-badge>
          </v-tab>
          <v-tab href="#notarrivaled" style="width: 180px">
            <template>納品待ち</template>
          </v-tab>
          <v-tab href="#notshipped" style="width: 180px">
            <template>納品済み</template>
          </v-tab>
          <v-tab href="#cancel" style="width: 180px"> キャンセル </v-tab>
        </v-tabs>
        <ag-grid-vue
          id="DeficiencyList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :columnTypes="columnTypes"
          :localeText="localeText"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="
            (data) => {
              return data.deficiencyNumber;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col v-if="detailSelected" id="OrderInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <deficiency-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :deficiencyNumber="selectedDeficiencyNumber"
        ></deficiency-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="answeredConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="補填品回答"
      message="TESTTEST"
      :columns="answeredConfirmColmuns"
      :autoClose="false"
    ></confirm-grid-dialog>
    <datepicker-confirm-grid-dialog
      ref="approvalConfirmGrid"
      width="80%"
      height="400px"
      icon="mdi-check"
      title="納品予約"
      message="TESTTEST"
      :columns="approvalConfirmColmuns"
      :autoClose="false"
      :abountDate="false"
    ></datepicker-confirm-grid-dialog>
    <bottom-right-attacher>
      <template>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unanswered'"
          @click="onBtnAnswered"
          :disabled="!allowedAction(constants.answeredActions) || !rowsSelected"
        >
          <v-icon>mdi-calendar-check</v-icon>補填品回答
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unreserved'"
          @click="onBtnDeliveryReservation"
          :disabled="!allowedAction(constants.reservationActions) || !rowsSelected"
        >
          <v-icon>mdi-calendar-check</v-icon>納品予約
        </v-btn>
      </template>
    </bottom-right-attacher>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import Validation from "../../libs/validation";
import DeficiencyInfos from "../../components/deficiency/DeficiencyInfos.vue";
import DeficiencyEntry from "../../components/deficiency/DeficiencyEntry.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import BottomRightAttacher from "../../components/common/BottomRightAttacher.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import DeficiencyStatuses from "../../consts/deficiencyStatus/MakerDeficiencyStatuses";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import DatepickerConfirmGridDialog from "../../components/common/DatepickerConfirmGridDialog.vue";
import { CustomDateCellEditor } from "../../components/ag-grid/cellEditors";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";

export default {
  name: "DeficiencySearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      // 権限グループ
      constants: {
        answeredActions: ["M020402"],
        reservationActions: ["M020403"],
      },
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isMinNumber: Validation.isMinNumber,
        isNextdayAfter: Validation.isNextdayAfter,
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      validSeearchForm: null,
      activeStatusTab: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      infoMaximum: false,
      paginationPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchModel: {
        createDateFrom: "",
        createDateTo: "",
      },
      editDialog: { show: false, width: "1800px" },
      editModel: {
        createNew: false,
      },
      selectionRows: null,
      statedDeficiency: null,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableDateColumn: EditableDateColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
        dpBooleanFilter: BooleanFilter,
      },
      makerColumnDefs: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", pinned: "left" },
        {
          headerName: "ステータス",
          field: "deficiencyStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: DeficiencyStatuses.all() },
          valueGetter: (params) => DeficiencyStatuses.of(params.data.deficiencyStatus),
          pinned: "left",
        },

        { headerName: "不足番号", field: "deficiencyNumber", pinned: "left" },
        { headerName: "発注ID", field: "purchaseId" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "納品数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不足数",
          field: "deficiencyQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "補填品有無", field: "replacementExistence", type: "dpCheckmarkColumn" },
        { headerName: "納品日", field: "deliveryReservation", type: "dpDateColumn" },
      ],
      answeredConfirmColmuns: [
        {
          headerName: "補填品有無",
          field: "doApproval",
          pinned: "left",
          type: "dpCheckmarkColumn",
        },
        { headerName: "不足番号", field: "deficiencyNumber", pinned: "left" },
        { headerName: "受注ID", field: "purchaseId" },
        { headerName: "日付", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "発注数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不足数",
          field: "deficiencyQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "補填品数",
          field: "replacementQuantity",
          type: "dpNumericColumn",
          editable: (patrams) => {
            return patrams.data.doApproval;
          },
          cellStyle: (patrams) => {
            return patrams.data.doApproval ? { backgroundColor: "#FFEDB3" } : {};
          },
        },
      ],
      approvalConfirmColmuns: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", pinned: "left" },
        { headerName: "不足番号", field: "deficiencyNumber", pinned: "left" },
        { headerName: "発注ID", field: "purchaseId" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "納品数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "補填品有無", field: "replacementExistence", type: "dpCheckmarkColumn" },
        {
          headerName: "不足数",
          field: "deficiencyQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "納品日",
          field: "deliveryReservation",
          type: "dpEditableColumn",
          cellEditorFramework: "CustomDateCellEditor",
          valueFormatter: DateValueFormatter("YYYY/MM/DD"),
        },
      ],
      errorColmuns: [
        { headerName: "不足番号", field: "deficiencyNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      mockData: {
        deficiency: [],
      },
    };
  },
  components: {
    AgGridVue,
    DeficiencyInfos,
    DeficiencyEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
    BottomRightAttacher,
    DatepickerConfirmGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
  },
  watch: {
    globalPageSize(size) {
      this.paginationPageSize = size;
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 48 + "px";
    },
  },
  computed: {
    unansweredCount() {
      return this.statedDeficiency && this.statedDeficiency.unanswered ? this.statedDeficiency.unanswered.length : null;
    },
    unapprovedCount() {
      return this.statedDeficiency && this.statedDeficiency.unapproved ? this.statedDeficiency.unapproved.length : null;
    },
    unreservedCount() {
      return this.statedDeficiency && this.statedDeficiency.unreserved ? this.statedDeficiency.unreserved.length : null;
    },
    deficiencyCount() {
      return this.statedDeficiency && this.statedDeficiency.deficiency ? this.statedDeficiency.deficiency.length : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }

      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          return !column.colDef.hide;
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          if (coldef.field == "createDatetime" || coldef.field == "deliveryReservation") {
            coldef.type = "dpFullDateColumn";
          }
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.deficiencyNumber, detailColumns, this.gridApi);
    },
    selectedDeficiencyNumber() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.deficiencyNumber;
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.onSearchClick();
    },
    onActiveStatusTabChanged(activeTab) {
      if (this.gridApi && this.statedDeficiency) {
        this.gridApi.setRowData(this.statedDeficiency[activeTab] || []);
      }
      this.selectedRow = null;
      this.rowsSelected = false;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "不足一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      this.selectionRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog.show = true;
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("deficiency/searchMaker", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "不足一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.deficiencies).length === 0) {
          this.$dialog.warning({
            title: "不足一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.statedDeficiency = response.data.contents.statedDeficiency || {};
        this.gridApi.setRowData(this.statedDeficiency[this.activeStatusTab] || []);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("DeficiencySearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDeficiencyDialogClose() {
      this.editDialog.show = false;
    },
    onDeficiencyEditSubmit(updateModel) {
      console.log(updateModel);
      this.editDialog.show = false;
      if (this.activeStatusTab == "unanswered") {
        this.gridApi.applyTransaction({ update: [updateModel] });
        this.statedDeficiency[this.activeStatusTab].forEach((statusData) => {
          if (statusData.deficiencyNumber == updateModel.deficiencyNumber) statusData = updateModel;
        });
      } else {
        this.gridApi.applyTransaction({ remove: [updateModel] });
        this.statedDeficiency["unanswered"].push(updateModel);
        let afterData = [];
        this.statedDeficiency[this.activeStatusTab].forEach((statusData) => {
          if (statusData.deficiencyNumber != updateModel.deficiencyNumber) afterData.push(statusData);
        });
        this.statedDeficiency[this.activeStatusTab] = afterData;
      }
    },
    onBtnAnswered() {
      const rows = [];
      this.gridApi.getSelectedRows().forEach((node) => {
        rows.push({
          doApproval: true,
          ...node,
          replacementQuantity: node.deficiencyQuantity,
        });
      });
      this.$refs.answeredConfirmGrid.open({
        records: rows,
        subtitle: `全: ${rows.length} 件`,
        onSubmit: async (records) => {
          if (this.validateRows(records, 1)) {
            try {
              this.loadingOn();
              let updateModels = [];
              records.forEach((row) => {
                updateModels.push({
                  replacementExistence: true,
                  deficiencyNumber: row.deficiencyNumber,
                  replacementQuantity: Number(row.replacementQuantity),
                  updateDateTime: row.updateDatetime,
                });
              });
              var requestContents = {
                replacementConditions: updateModels,
              };
              const result = await this.$store.dispatch("deficiency/replacementquantity", requestContents);

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        deficiencyNumber: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.deficiencies) {
                    this.statedDeficiency[this.activeStatusTab].forEach((statusData) => {
                      let diffFlg = false;
                      result.data.contents.deficiencies.forEach((deficiency) => {
                        if (deficiency.deficiencyNumber == statusData.deficiencyNumber) {
                          statusData.deficiencyStatus = deficiency.deficiencyStatus;
                          statusData.updateDatetime = deficiency.updateDatetime;
                          removeRow.push(statusData);
                          this.statedDeficiency["unapproved"].push(statusData);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(statusData);
                    });
                    this.statedDeficiency[this.activeStatusTab] = afterRows;
                  }
                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    this.$refs.bulkErrorGrid.open({ title: "補填品回答", records: errorRows });
                    this.$dialog.notify.error(`補填品回答に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`補填品を回答しました`, {
                      timeout: 2300,
                    });
                    this.$refs.answeredConfirmGrid.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } catch (error) {
              console.error("DeficiencySearch::approvalConfirmGrid onsubmit", error);
              this.apiRequestError(error);
            } finally {
              this.loadingOff();
            }
          }
        },
      });
    },
    onBtnDeliveryReservation() {
      this.$refs.approvalConfirmGrid.open({
        records: JSON.parse(JSON.stringify(this.selectionRows)),
        subtitle: `全: ${this.selectionRows.length} 件`,
        onSubmit: async (records) => {
          if (this.validateRows(records, 2)) {
            try {
              this.loadingOn();
              let updateModels = [];
              records.forEach((row) => {
                updateModels.push({
                  deficiencyNumber: row.deficiencyNumber,
                  deliveryReservation: row.deliveryReservation,
                  updateDatetime: row.updateDatetime,
                });
              });
              var requestContents = {
                reservationConditions: updateModels,
              };
              const result = await this.$store.dispatch("deficiency/deliveryreservation", requestContents);

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        deficiencyNumber: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.deficiencies) {
                    this.selectionRows.forEach((statusData) => {
                      let diffFlg = false;
                      result.data.contents.deficiencies.forEach((deficiency) => {
                        if (deficiency.deficiencyNumber == statusData.deficiencyNumber) {
                          statusData.deficiencyStatus = deficiency.deficiencyStatus;
                          statusData.updateDatetime = deficiency.updateDatetime;
                          removeRow.push(statusData);
                          this.statedDeficiency["notarrivaled"].push(statusData);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(statusData);
                    });
                    this.statedDeficiency[this.activeStatusTab] = afterRows;
                  }
                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    this.$refs.bulkErrorGrid.open({ title: "納品日回答", records: errorRows });
                    this.$dialog.notify.error(`納品日回答に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`納品日を回答しました`, {
                      timeout: 2300,
                    });
                    this.$refs.approvalConfirmGrid.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } catch (error) {
              console.error("DeficiencySearch::approvalConfirmGrid onsubmit", error);
              this.apiRequestError(error);
            } finally {
              this.loadingOff();
            }
          }
        },
      });
    },
    validateRows(rows, type) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row) => {
        var error;
        if (type == 1) {
          error = this.onValidAnswered(row);
        } else if (type == 2) {
          error = this.onValidReservation(row);
        }
        if (error != true) {
          errorRows.push({
            deficiencyNumber: row.deficiencyNumber,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: "補填品回答", records: errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidAnswered(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      // 補填品数
      columnName = "補填品数";
      checkValue = row.replacementQuantity;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNumber(checkValue), columnName, messages);
      this.setValidMessage(this.rules.maxLength(8)(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isMinNumber(1)(checkValue), columnName, messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    onValidReservation(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      // 納品日
      columnName = "納品日";
      checkValue = row.deliveryReservation;
      this.setValidMessage(this.rules.required(checkValue), columnName, messages);
      this.setValidMessage(this.rules.isNextdayAfter(checkValue), columnName, messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    createDateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.createDateTo)) return "登録日Toより前の日付を指定してください";
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.createDateFrom)) return "登録日Fromより後の日付を指定してください";
      return true;
    },
  },
  async mounted() {
    this.gridStyle.height = this.gridHeightSize - 48 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.paginationPageSize = this.globalPageSize;
    this.domLayout = "autoHeight";
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];
    this.columnDefs = this.columnDefs.concat(this.makerColumnDefs);

    this.rowData = [];
  },
};
</script>
