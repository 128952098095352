<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.rowDisabled {
  background-color: lemonchiffon !important;
}
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-note-plus</v-icon>発注管理</v-app-bar-title>
        <v-spacer></v-spacer>

        <template v-if="!entryStocks">
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <v-select
                    clearable
                    v-model="searchModel.orderStatus"
                    :items="orderStatuses"
                    label="受注ステータス"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    v-model="searchModel.orderDateFrom"
                    type="date"
                    :label="isCafereoUser ? '発注日From' : '受注日From'"
                    dense
                    :rules="[rules.orderDateFrom]"
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    v-model="searchModel.orderDateTo"
                    type="date"
                    :label="isCafereoUser ? '発注日To' : '受注日To'"
                    dense
                    :rules="[rules.orderDateTo]"
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.maker"
                    class="mx-2"
                    :rules="[rules.maxLength(150)]"
                    label="仕入先略称"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    v-model="searchModel.jancode"
                    class="mx-2"
                    :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                    label="JANコード"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.title"
                    class="mx-2"
                    :rules="[rules.maxLength(60)]"
                    label="タイトル"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.productName"
                    class="mx-2"
                    :rules="[rules.maxLength(60)]"
                    label="商品名"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="発注数From"
                    class="mx-2"
                    @change="searchModel.purchaseQuantityFrom = stringToNumber($event)"
                    v-model="searchModel.purchaseQuantityFrom"
                    :rules="[rules.isNumber, rules.maxLength(8), rules.isNumber]"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="発注数To"
                    class="mx-2"
                    @change="searchModel.purchaseQuantityTo = stringToNumber($event)"
                    v-model="searchModel.purchaseQuantityTo"
                    :rules="[rules.isNumber, rules.maxLength(8), rules.isNumber]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    v-model="searchModel.makerRemarks"
                    class="mx-2"
                    :rules="[rules.maxLength(256)]"
                    label="メーカー備考"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    v-model="searchModel.cafereoRemarks"
                    class="mx-2"
                    :rules="[rules.maxLength(256)]"
                    label="カフェレオ備考"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <cafereo-user-field
                    ref="purchasePerson"
                    label="担当者"
                    :value="searchModel.customerStaff"
                    v-model="searchModel.customerStaff"
                    :attach="false"
                  ></cafereo-user-field>
                </v-col>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical class="mx-2"></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C030202'])"
            :disabled="!rowsSelected2"
            icon="mdi-check"
            @click="onBtnRegist"
            >発注登録</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-download" @click="onBtnExport">CSVダウンロード</tooltip-icon-button>
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-dialog v-model="registDialog.show" :max-width="registDialog.width" persistent>
            <purchase-regist
              :inputRows="registRows"
              :entryStocks="entryStocks"
              @complete="onRegistComplete"
              @expand="registDialog.width = $event ? '100%' : '800px'"
              v-if="registDialog.show"
            ></purchase-regist>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button v-if="allowedAction(['C030204'])" icon="mdi-database-search" @click="onBtnStocks"
            >在庫表から登録</tooltip-icon-button
          >
        </template>

        <template v-if="entryStocks">
          <v-form ref="searchStockForm" v-model="validStockSeearchForm" lazy-validation>
            <search-conditions @search="onBtnStockSearch" max-height="500px">
              <v-select
                clearable
                label="商品ステータス"
                v-model="searchStockModel.productStatus"
                :items="productStatuses"
              ></v-select>
              <dp-date-picker
                v-model="searchStockModel.createDateFrom"
                type="date"
                label="登録日付From"
                dense
                :rules="[rules.createDateFrom]"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchStockModel.createDateTo"
                type="date"
                label="登録日付To"
                dense
                :rules="[rules.createDateTo]"
              ></dp-date-picker>
            </search-conditions>
          </v-form>
          <v-divider vertical class="mx-2"></v-divider>
          <tooltip-icon-button
            :disabled="!this.selectionRowsStocks.length > 0"
            icon="mdi-check"
            @click="onBtnRegistStocks"
            >発注登録</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFiltersStocks()">フィルター解除</tooltip-icon-button>
          <v-dialog v-model="registDialog.show" :max-width="registDialog.width" persistent>
            <purchase-regist
              :inputRows="registRows"
              :entryStocks="entryStocks"
              @complete="onRegistComplete"
              @expand="registDialog.width = $event ? '100%' : '800px'"
              v-if="registDialog.show"
            ></purchase-regist>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button icon="mdi mdi-book-search" @click="onBtnStocks">受注から登録</tooltip-icon-button>
        </template>
      </v-app-bar>
    </v-row>
    <v-row style="margin-top: 0px !important">
      <v-col style="height: 100%; flex-basis: auto" v-show="!entryStocks">
        <v-row style="margin-top: 0px !important">
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                発注数合計：{{ this.purchaseQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                仕入金額合計：￥{{ this.purchaseAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                在庫数合計：{{ this.stockQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                在庫金額合計：￥{{ this.stockAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6" dense style="margin-top: 0px !important">
          <v-col class="text-h5 mt-5" cols="12">■発注対象</v-col>
          <v-col cols="12" :style="gridStyle">
            <ag-grid-vue
              id="purchaseList"
              class="ag-theme-alpine"
              style="height: 100%"
              :defaultColDef="defaultColDef"
              :columnDefs="purchaseColumnDefs"
              :alwaysShowHorizontalScroll="true"
              :pagination="true"
              :paginationPageSize="selectedPageSize"
              :suppressCsvExport="false"
              :suppressExcelExport="true"
              :defaultCsvExportParams="defaultCsvExportParams"
              :localeText="localeText"
              :enableCellTextSelection="true"
              :suppressColumnVirtualisation="true"
              :columnTypes="columnTypes"
              :frameworkComponents="frameworkComponents"
              rowSelection="multiple"
              :rowData="purchaseRowData"
              @grid-ready="onPurchaseGridReady"
              @selection-changed="onPuchaseSelectionChanged"
              @filter-changed="onfilterChanged"
            >
            </ag-grid-vue>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6" dense v-if="purchaseSelectedRow">
          <v-col class="text-h5" cols="12">■内訳</v-col>
          <v-col cols="12" :style="gridStyle">
            <ag-grid-vue
              id="OrderList"
              class="ag-theme-alpine"
              style="height: 100%"
              :defaultColDef="defaultColDef"
              :columnDefs="orderColumnDefs"
              :rowData="rowData"
              :alwaysShowHorizontalScroll="true"
              :pagination="true"
              :paginationPageSize="selectedPageSize"
              :localeText="localeText"
              rowSelection="multiple"
              :enableCellTextSelection="true"
              :suppressColumnVirtualisation="true"
              :columnTypes="columnTypes"
              :frameworkComponents="frameworkComponents"
              @grid-ready="onGridReady"
              @selection-changed="onOrderSelectionChanged"
              :rowClassRules="rowClassRules"
            >
            </ag-grid-vue>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="entryStocks" style="flex-basis: auto; display: flex" cols="12">
        <purchase-from-stocks
          ref="PurchaseFromStocks"
          :searchModel="searchStockModel"
          @onSelectionChanged="onSelectionChangedStocks"
        ></purchase-from-stocks>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import "ag-grid-community";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter, AmbiguousDateFilter } from "../../components/ag-grid/filters";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import PurchaseRegist from "../../components/purchase/PurchaseRegist.vue";
import PurchaseFromStocks from "../../components/purchase/PurchaseFromStocks.vue";
import ProductOrderTypes from "../../consts/ProductOrderTypes";
import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import OrderTypes from "../../consts/OrderTypes";
import ProductTypes from "../../consts/ProductTypes";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import PurchaseTypes from "../../consts/PurchaseTypes";
import { typeFormat } from "../../models/ag-grid/helpers";
import Validation from "../../libs/validation";
import ConvertUtils from "../../utils/ConvertUtils";

export default {
  name: "PurchaseEntry",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    PurchaseRegist,
    PurchaseFromStocks,
    CafereoUserField,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      rules: {
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isJancodeSearchList: Validation.isJancodeSearchList,
        orderDateFrom: (value) => this.orderDateFromRules(value),
        orderDateTo: (value) => this.orderDateToRules(value),
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      stringToNumber: ConvertUtils.stringToNumber,
      validSeearchForm: null,
      validStockSeearchForm: null,
      columnDefs: null,
      purchaseRowData: [],
      rowData: [],
      purchaseGridApi: null,
      purchaseColumnApi: null,
      orderGridApi: null,
      orderColumnApi: null,
      defaultCsvExportParams: null,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpAmbiguousDateFilter: AmbiguousDateFilter,
      },
      rowsSelected: null,
      rowsSelected2: null,
      isRowSelectable: null,
      rowClassRules: null,
      imageList: [],
      infoMaximum: false,
      orderStatuses: CafereoOrderStatuses.all(),
      productStatuses: CafereoProductStatus.all().map((status) => {
        return status;
      }),
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      orderSelectedRow: null,
      purchaseSelectedRow: null,
      searchModel: {
        orderStatus: null,
        orderDateFrom: null,
        orderDateTo: null,
        customerStaff: this.$store.getters["security/loggedInUserId"],
      },
      searchStockModel: {
        productStatus: null,
        createDateFrom: null,
        createDateTo: null,
      },
      searchDetailModel: {
        orderIds: [],
      },
      orderModel: {
        createNew: false,
      },
      registDialog: { show: false, width: "800px" },
      registRows: null,
      entryStocks: false,
      selectionRowsStocks: [],
      purchaseQuantity: 0,
      purchaseAmount: 0,
      stockQuantity: 0,
      stockAmount: 0,
    };
  },
  watch: {
    purchaseSelectedRow(row) {
      if (row != null) this.onSearchDetail(row.orderIdList);
    },
    globalPageSize(size) {
      if (this.orderGridApi) this.orderGridApi.paginationSetPageSize(size);
      if (this.purchaseGridApi) this.purchaseGridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = (value - 120.5) * 0.8 + "px";
    },
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.gridStyle.height = (this.gridHeightSize - 120.5) * 0.8 + "px";
    window.addEventListener("resize", this.handleResize);
    // 検索ステータスを発注済み以降は削除
    this.orderStatuses.splice(CafereoOrderStatuses.APPROVED);
  },
  methods: {
    clearFilters() {
      if (this.orderGridApi != null && !this.orderGridApi.destroyCalled) {
        this.orderGridApi.setFilterModel(null);
        this.orderColumnApi.applyColumnState({
          defaultState: { sort: null },
        });
      }
      this.purchaseGridApi.setFilterModel(null);
      this.purchaseColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    clearFiltersStocks() {
      this.$refs.PurchaseFromStocks.clearFilters();
    },
    onGridReady(params) {
      this.orderGridApi = params.api;
      this.orderColumnApi = params.columnApi;
    },
    onPurchaseGridReady(params) {
      this.purchaseGridApi = params.api;
      this.purchaseColumnApi = params.columnApi;
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    onBtnStockSearch() {
      const isValid = this.$refs.searchStockForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchStockForm.resetValidation();
      this.$refs.PurchaseFromStocks.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        var params = { ...this.searchModel };
        // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
        params.jancode = this.searchModel.jancode
          ? this.searchModel.jancode
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        let response = await this.$store.dispatch("purchase/purchasEntrySearch", { searchConditions: params });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "発注対象一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.purchases).length === 0) {
          this.$dialog.warning({
            title: "発注対象一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.purchaseGridApi.setRowData(result.purchases);
        this.purchaseQuantity = 0;
        this.purchaseAmount = 0;
        this.stockQuantity = 0;
        this.stockAmount = 0;
        for (let row of result.purchases) {
          if (row.purchaseQuantity) {
            this.purchaseQuantity += row.purchaseQuantity;
          }
          if (row.stockQuantity) {
            this.stockQuantity += row.stockQuantity;
          }
          if (row.stockPrice) {
            this.stockAmount += row.stockPrice;
          }
          if (row.purchasePriceTotal) {
            this.purchaseAmount += row.purchasePriceTotal;
          }
        }

        var allColumnIds = [];
        this.purchaseColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.purchaseColumnApi.autoSizeColumns(allColumnIds);
        this.purchaseSelectedRow = null;
      } catch (error) {
        console.log(error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onSearchDetail(ids) {
      try {
        this.loadingOn();

        let orderIdList = { orderIdList: [...ids] };
        let response = await this.$store.dispatch("purchase/orderSearch", orderIdList);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "発注対象一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        this.orderGridApi.setRowData(result.orders);

        var allColumnIds = [];
        this.orderColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.orderColumnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.log(error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnExport() {
      var allColumnIds = [];
      this.purchaseColumnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "発注対象一覧.csv";
      this.purchaseGridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onOrderSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.orderGridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      if (selectedRows.length === 1) {
        this.orderSelectedRow = selectedRows[0];
      } else {
        this.orderSelectedRow = null;
      }
    },
    onPuchaseSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.purchaseGridApi.getSelectedRows();
      this.rowsSelected2 = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      if (selectedRows.length === 1) {
        this.purchaseSelectedRow = selectedRows[0];
      } else {
        this.purchaseSelectedRow = null;
      }
    },
    onfilterChanged() {
      this.purchaseQuantity = 0;
      this.purchaseAmount = 0;
      this.stockQuantity = 0;
      this.stockAmount = 0;
      this.purchaseGridApi.forEachNodeAfterFilter((row) => {
        if (row.displayed) {
          if (row.data.purchaseQuantity) {
            this.purchaseQuantity += row.data.purchaseQuantity;
          }
          if (row.data.stockQuantity) {
            this.stockQuantity += row.data.stockQuantity;
          }
          if (row.data.stockPrice) {
            this.stockAmount += row.data.stockPrice;
          }
          if (row.data.purchasePriceTotal) {
            this.purchaseAmount += row.data.purchasePriceTotal;
          }
        }
      });
    },
    onBtnRegist() {
      this.registRows = this.purchaseGridApi.getSelectedRows();
      this.registDialog.show = true;
    },
    onPageSizeChanged() {
      this.orderGridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onPageSizeChanged2() {
      this.purchaseGridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.orderGridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onRegistComplete(canceled) {
      this.registDialog.show = false;
      if (!canceled) this.onSearchClick();
    },
    onBtnStocks() {
      this.entryStocks = !this.entryStocks;
    },
    onSelectionChangedStocks(rows) {
      this.selectionRowsStocks = rows;
    },
    onBtnRegistStocks() {
      this.registRows = [];
      for (let row of this.selectionRowsStocks) {
        row.purchaseType = "買切";
        this.registRows.push(row);
      }
      this.registDialog.show = true;
    },
    orderDateFromRules(value) {
      if (value == null || this.searchModel.orderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.orderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    orderDateToRules(value) {
      if (value == null || this.searchModel.orderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.orderDateFrom)) return "発注日Fromより後の日付を指定してください";
      return true;
    },
    createDateFromRules(value) {
      if (value == null || this.searchStockModel.createDateTo == null) return true;
      if (moment(value).isAfter(this.searchStockModel.createDateTo)) return "登録日Toより前の日付を指定してください";
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchStockModel.createDateFrom == null) return true;
      if (moment(value).isBefore(this.searchStockModel.createDateFrom))
        return "登録日Fromより後の日付を指定してください";
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.rowClassRules = {
      rowDisabled: (params) => {
        return params.data.registered;
      },
    };
    // this.isRowSelectable = (rowNode) => {
    //   return !rowNode.data.registered;
    // };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.orderColumnDefs = [
      { headerName: "受注ID", field: "orderId", pinned: "left" },
      { headerName: "指示書NO", field: "directionNumber" },
      {
        headerName: "受注区分",
        field: "orderType",
        filter: "dpSelectionFilter",
        filterParams: { options: OrderTypes.all() },
        valueGetter: (params) => OrderTypes.of(params.data.orderType),
      },
      {
        headerName: "区分",
        field: "productOrderType",
        filter: "dpSelectionFilter",
        filterParams: { options: ProductOrderTypes.all() },
        valueGetter: (params) => ProductOrderTypes.of(params.data.productOrderType),
      },
      {
        headerName: "商品区分",
        field: "productType",
        filter: "dpSelectionFilter",
        filterParams: { options: ProductTypes.all() },
        valueGetter: (params) => ProductTypes.of(params.data.productType),
      },
      {
        headerName: "受注ステータス",
        field: "orderStatus",
        filter: "dpSelectionFilter",
        filterParams: { options: CafereoOrderStatuses.all() },
        valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
      },
      { headerName: "フォーキャスト受注", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
      { headerName: "登録日", field: "orderDate", type: "dpDateColumn" },
      { headerName: "社店CD", field: "customerCode" },
      { headerName: "法人名", field: "corporateName" },
      { headerName: "取引先名", field: "customerName" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
      { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
      { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "入荷予定日", field: "arrivalPlanDate", type: "dpDateColumn" },
      { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
      { headerName: "受注単位", field: "orderUnit" },
      { headerName: "受注数", field: "orderQuantity" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      {
        headerName: "上限数",
        field: "maximumNumber",
        type: "dpNumericColumn",
      },
      {
        headerName: "卸掛率",
        field: "wholesaleRate",
        type: "dpPercentColumn",
        valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
      },
      {
        headerName: "卸単価",
        field: "unitPrice",
        type: "dpNumericColumn",
      },
      { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
      { headerName: "メーカー名", field: "supplierName" },
      {
        headerName: "仕入掛率",
        field: "purchaseRate",
        type: "dpPercentColumn",
        valueGetter: (params) => params.data.purchaseRate.toFixed(1),
      },
      {
        headerName: "仕入単価",
        field: "purchasePrice",
        type: "dpNumericColumn",
      },
      { headerName: "粗利単価", field: "grossProfit", type: "dpNumericColumn" },
      { headerName: "粗利合計金額", field: "grossProfitTotal", type: "dpNumericColumn" },
      { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
      { headerName: "配送CD", field: "deliveryCode" },
      { headerName: "配送先住所", field: "deliveryAddress" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: "届先名称", field: "deliveryName2" },
      { headerName: "確保倉庫", field: "insureStorageName" },
      { headerName: "伝票番号", field: "slipNumber" },
      { headerName: "伝票備考", field: "slipRemarks" },
      { headerName: "親受注ID", field: "parentOrderId" },
      { headerName: "フォーキャスト割当分", field: "assignedForecastFlg", type: "dpCheckmarkColumn" },
      { headerName: "一括取込番号", field: "importNumber" },
      { headerName: "担当チーム", field: "salesTeam" },
      { headerName: "営業担当", field: "salesStaff" },
      { headerName: "アシスタント", field: "salesAssistant" },
      { headerName: "社内備考", field: "cafereoRemarks" },
      { headerName: "PO番号", field: "poNumber" },
      { headerName: "ハイコン", field: "hycon", type: "dpDateColumn" },
    ];
    this.purchaseColumnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "仕入先略称",
        field: "maker",
      },
      {
        headerName: "発注区分",
        field: "purchaseType",
        filter: "dpSelectionFilter",
        filterParams: { options: PurchaseTypes.all() },
        valueGetter: (params) => PurchaseTypes.of(params.data.purchaseType),
      },
      { headerName: "発注締日", field: "orderClosingDate", type: "dpDateColumn" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
      { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
      { headerName: "単位", field: "unit", type: "dpNumericColumn" },
      {
        headerName: "上限数",
        field: "maximumQuantity",
        type: "dpNumericColumn",
      },
      { headerName: "発注数", field: "purchaseQuantity", type: "dpNumericColumn" },
      { headerName: "未出荷受注数", field: "unshipedOrderTotalQuantity", type: "dpNumericColumn" },
      { headerName: "未入荷発注数", field: "unarrivaledPurchaseTotalQuantity", type: "dpNumericColumn" },
      {
        headerName: "在庫数",
        field: "stockQuantity",
        type: "dpNumericColumn",
      },
      { headerName: "Box入数", field: "inBoxNumber", type: "dpNumericColumn" },

      { headerName: "発注数(psc)", field: "purchasePcsQuantity", type: "dpNumericColumn" },

      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
      { headerName: "仕入単価", field: "unitPrice", type: "dpNumericColumn" },
      { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
      {
        headerName: "在庫金額",
        field: "stockPrice",
        type: "dpNumericColumn",
      },
      { headerName: "発売日", field: "releaseDate", filter: "dpAmbiguousDateFilter" },
      { headerName: "直送依頼", field: "directDeliveryRequest", type: "dpCheckmarkColumn" },
      { headerName: "直送可否", field: "directDeliveryLocalApproval", type: "dpCheckmarkColumn" },
      { headerName: "配送コード", field: "deliveryCode" },
      { headerName: "配送先郵便番号", field: "deliveryPostalCode" },
      { headerName: "配送先住所", field: "deliveryAddress" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: " 届先名称", field: "deliveryName2" },
      { headerName: "メーカー備考", field: "purchaseRemarks" },
      { headerName: "Cafereo備考", field: "cafereoRemarks" },
    ];
  },
};
</script>
