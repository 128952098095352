<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>発注一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="purchaseBulkEditForm" lazy-validation>
        <v-container>
          <section-block>
            <v-row v-for="(source, index) in settingSources" :key="index" dense>
              <v-col cols="3">
                <v-select
                  :items="constant.purchaseBulkEditColumnsValues"
                  label="設定項目"
                  v-model="source.column"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @change="onSettingColSelected(index)"
                  dense
                  filled
                  attach
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-if="source.column === constant.purchaseBulkEditColumns.PURCHASE_QUANTITY"
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(8), rules.rangeOfNumber(1)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.purchaseBulkEditColumns.UNIT_PRICE"
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(13)]"
                  filled
                  dense
                >
                </v-text-field>
                <dp-date-picker
                  v-if="source.column === constant.purchaseBulkEditColumns.DELIVERY_TIME"
                  type="about"
                  :hideAbout="hideAbout"
                  label="設定値"
                  v-model="source.value"
                ></dp-date-picker>
                <dp-date-picker
                  v-if="source.column === constant.purchaseBulkEditColumns.DELIVERY_RESERVATION"
                  type="date"
                  label="設定値"
                  v-model="source.value"
                ></dp-date-picker>
                <v-select
                  v-if="source.column === constant.purchaseBulkEditColumns.DELIVERY_COMPANY"
                  v-model="source.value"
                  :items="constant.deliveryCompanyList"
                  label="配送業者"
                  dense
                  filled
                  attach
                ></v-select>
                <cafereo-user-field
                  ref="purchasePerson"
                  v-if="source.column === constant.purchaseBulkEditColumns.PURCHASE_PERSON"
                  label="カフェレオ担当者"
                  v-model="source.value"
                  :attach="false"
                  @selected="onPurchasePersonChanged"
                ></cafereo-user-field>
                <v-text-field
                  v-if="source.column === constant.purchaseBulkEditColumns.MAKER_PERSON"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(100)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="
                    source.column === constant.purchaseBulkEditColumns.MAKER_REMARKS ||
                    source.column === constant.purchaseBulkEditColumns.CAFEREO_REMARKS
                  "
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(30)]"
                  filled
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="1"
                ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                  <v-icon dark> mdi-minus </v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn x-small fab dense @click="onAddSettingSource">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </section-block>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="!selectedRows.length > 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetUpdate"
                >一括反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 100%"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                :suppressColumnVirtualisation="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                :enableCellTextSelection="true"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @column-everything-changed="onColumnEverythingChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.purchaseId"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                @cell-editing-started="onCellEditingStarted"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="発注一括編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
} from "../../models/ag-grid/columnTypes";
import { LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getColumnDef } from "../../models/ag-grid/helpers";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  AboutDateCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { CafereoEditable as CafereoColumnDefs } from "../../consts/columns/PurchaseColumns";
import PurchaseBulkEditColumns from "../../consts/editColumns/PurchaseBulkEditColumns";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import CafereoPurchaseStatuses from "../../consts/purchaseStatus/CafereoPurchaseStatuses";
import PurchaseTypes from "../../consts/PurchaseTypes";
import ConvertUtils from "../../utils/ConvertUtils";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";

export default {
  name: "PurchaseBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
    AboutDateCellEditor,
    NumericCellEditor,
    SectionBlock,
    CafereoUserField,
  },
  data() {
    return {
      updateModel: {},
      defaultModel: {
        shipDate: null,
        specifiedDeliveryDate: null,
        isReady: null,
        cartonNumber: null,
        useSagawa: null,
        useCharter: null,
        priorityAm: null,
        useOricon: null,
        gachaProducts: null,
        bundledDesignation: null,
        preShipment: null,
        cashOnDelivery: null,
        useShippingLabel: null,
        enhancedInspection: null,
        remarks: null,
      },
      constant: {
        purchaseBulkEditColumnsValues: PurchaseBulkEditColumns.all(),
        purchaseBulkEditColumns: PurchaseBulkEditColumns,
        deliveryCompanyList: DeliveryCompanys.all(),
      },
      hideAbout: ["pending"],
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpCellAboutDatePicker: AboutDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
      },
      errorColmuns: [
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      stringToNumber: ConvertUtils.stringToNumber,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        rangeOfNumber: Validation.rangeOfNumber,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
      purchasePersonList: [],
      purchasePersonNames: "",
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    async setColumnDefs() {
      // カフェレオ担当者サジェスト
      try {
        this.purchasePersonList = [];
        const userResponse = await this.$store.dispatch("cafereoUser/search");
        if (userResponse.data?.contents?.users) {
          var users = userResponse.data.contents.users;
          for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const purchasePersonInfo = {
              value: user.userId,
              label: user.userName,
            };
            this.purchasePersonList.push(purchasePersonInfo);
          }
        }
      } catch (error) {
        console.error("PurchaseBulkEdit::setColumnDefs cafereoUser/search", error);
        this.apiRequestError(error);
      }

      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "purchaseLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "purchaseLocked" })(params);
            }
          },
        },
      ];
      columnDefs = columnDefs.concat(CafereoColumnDefs);
      // ag-grid内のvalidation等をセット
      getColumnDef(columnDefs, "purchaseQuantity").valueSetter = this.valueSetter4PurchaseQuantity;
      getColumnDef(columnDefs, "unitPrice").valueSetter = this.valueSetter4UnitPrice;
      getColumnDef(columnDefs, "purchasePerson").cellEditorParams = this.cellEditorPurchasePersonName(); // カフェレオ担当者サジェスト
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onCellEditingStarted(event) {
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    valueSetter4PurchaseQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("発注数は" + isNumber, { timeout: 2300 });
        params.data.errorPurchaseQuantity = true;
        return false;
      }
      if (params.data.purchaseStatus === CafereoPurchaseStatuses.ARRIVAL) {
        this.$dialog.notify.error("入荷済みのため発注数は変更できません。", { timeout: 2300 });
        params.data.errorPurchaseQuantity = true;
        return false;
      }
      params.data.errorPurchaseQuantity = false;
      params.data.purchaseQuantity = params.newValue;
      this.computePurchaseItems(params.data);
      return true;
    },
    valueSetter4UnitPrice(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("仕入価格は" + isNumber, { timeout: 2300 });
        params.data.errorUnitPrice = true;
        return false;
      }
      if (params.data.purchaseType !== 1 && Number(params.oldValue) === 0) {
        this.$dialog.notify.error("買切り以外で仕入価格が0の場合は仕入価格を設定できません。", { timeout: 2300 });
        params.data.errorUnitPrice = true;
        return false;
      }
      params.data.unitPrice = params.newValue;
      this.computePurchaseItems(params.data);
      return true;
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.purchaseBulkEditForm) {
        this.$refs.purchaseBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onDialogClose", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode((record) => {
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            purchaseId: record.data.purchaseId,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          var param = {
            rows: requestRecords,
            splitNum: 100,
          };
          console.log(param);
          const response = await this.$store.dispatch("purchase/bulkupdate", param);
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.purchase.forEach((purchase) => {
                    let isError = this.errorRows.some((r) => r.purchaseId === row.data.purchaseId);
                    if (purchase.purchaseId == row.data.purchaseId && !isError) {
                      this.updateSuccessRecords.push(purchase);
                      updateRows.push(purchase);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`発注情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`発注情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                console.log("発注情報を更新しました::");
                console.log(this.updateSuccessRecords);
                this.$emit("onEditSubmit", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseBulkEdit_update::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    requestFormat(row) {
      row.unitPrice = String(row.unitPrice);
      row.retailPrice = String(row.retailPrice);
      row.purchaseRate = String(row.purchaseRate);
      row.deliveryCompany = String(row.deliveryCompany);
      row.purchaseQuantity = Number(row.purchaseQuantity);
      row.updateDatetime = row.updateDate;
      row.purchasePerson = row.purchasePerson.value ? String(row.purchasePerson.value) : String(row.purchasePerson);
      return row;
    },
    onCellValueChanged(params) {
      console.log("params:");
      console.log(params);
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "purchaseQuantity") {
        params.data.updatePurchaseQuantity = true;
      } else if (params.colDef.field == "unitPrice") {
        params.data.updateUnitPrice = true;
      } else if (params.colDef.field == "deliveryTime") {
        params.data.updateDeliveryTime = true;
      } else if (params.colDef.field == "deliveryReservation") {
        params.data.updateDeliveryReservation = true;
      } else if (params.colDef.field == "deliveryCompany") {
        params.data.updateDeliveryCompany = true;
      } else if (params.colDef.field == "purchaseRemarks") {
        params.data.updatePurchaseRemarks = true;
      } else if (params.colDef.field == "purchasePerson") {
        params.data.updatePurchasePerson = true;
      } else if (params.colDef.field == "makerPerson") {
        params.data.updateMakerPerson = true;
      } else if (params.colDef.field == "cafereoRemarks") {
        params.data.updateCafereoRemarks = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    onBtnSetUpdate() {
      this.loadingOn();
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            console.log(source);
            if (source.column === this.constant.purchaseBulkEditColumns.PURCHASE_QUANTITY) {
              if (row.purchaseStatus !== CafereoPurchaseStatuses.ARRIVAL) {
                row.purchaseQuantity = source.value;
                row.updatePurchaseQuantity = true;

                this.computePurchaseItems(row);
              }
            } else if (source.column === this.constant.purchaseBulkEditColumns.UNIT_PRICE) {
              if (row.purchaseType !== PurchaseTypes.PURCHASE && row.unitPrice === 0) {
                // dummy
              } else {
                row.unitPrice = source.value;
                row.updateUnitPrice = true;

                this.computePurchaseItems(row);
              }
            } else if (source.column === this.constant.purchaseBulkEditColumns.DELIVERY_TIME) {
              row.deliveryTime = source.value;
              row.updateDeliveryTime = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.DELIVERY_RESERVATION) {
              row.deliveryReservation = source.value;
              row.updateDeliveryReservation = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.DELIVERY_COMPANY) {
              row.deliveryCompany = source.value;
              row.updateDeliveryCompany = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.MAKER_REMARKS) {
              row.purchaseRemarks = source.value;
              row.updatePurchaseRemarks = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.PURCHASE_PERSON) {
              row.purchasePerson = source.value;
              row.purchasePersonName = this.purchasePersonNames;
              row.updatePurchasePerson = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.MAKER_PERSON) {
              row.makerPerson = source.value;
              row.updateMakerPerson = true;
            } else if (source.column === this.constant.purchaseBulkEditColumns.CAFEREO_REMARKS) {
              row.cafereoRemarks = source.value;
              row.updateCafereoRemarks = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
      this.loadingOff();
    },
    // ****************************
    // 共通業務処理
    // ****************************
    computePurchaseItems(row) {
      row.purchasePcsQuantity = row.inBoxNumber * row.purchaseQuantity;
      if (row.retailPrice > row.unitPrice) {
        row.purchaseRate = Math.round((row.unitPrice / row.retailPrice) * 100);
      }
      row.purchasePriceTotal = row.unitPrice * row.purchaseQuantity;
    },

    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.purchaseBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 発注数
      this.setValidMessage(this.rules.required(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.isNumber(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.rangeOfNumber(1)(row.purchaseQuantity), "発注数", messages);
      this.setCustomValidMessage(this.validPurchaseQuantity(row), messages);
      // 仕入価格
      this.setValidMessage(this.rules.required(row.unitPrice), "仕入価格", messages);
      this.setValidMessage(this.rules.isNumber(row.unitPrice), "仕入価格", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.unitPrice), "仕入価格", messages);
      this.setCustomValidMessage(this.validUnitPrice(row), messages);
      // 納品予定日
      this.setValidMessage(this.rules.maxLength(30)(row.deliveryTime), "納品予定日", messages);
      // 納品予約
      this.setValidMessage(this.isDate(row.deliveryReservation), "納品予約", messages);
      // 備考(メーカー表示)
      this.setValidMessage(this.rules.maxLength(30)(row.purchaseRemarks), "備考(メーカー表示)", messages);
      // カフェレオ担当者
      this.setValidMessage(this.rules.required(row.purchasePerson), "カフェレオ担当者", messages);
      // メーカー担当者
      this.setValidMessage(this.rules.maxLength(100)(row.makerPerson), "メーカー担当者", messages);
      // 社内備考１
      this.setValidMessage(this.rules.maxLength(30)(row.cafereoRemarks), "社内備考１", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    validUnitPrice(row) {
      if (row == null) return true;
      if (Number(row.retailPrice) < Number(row.unitPrice)) return "仕入価格が上代を超えています。";
      return true;
    },
    validPurchaseQuantity(row) {
      if (row == null) return true;
      if (Number(row.purchaseQuantity) < Number(row.unit)) return "仕入数が発注単位を満たしていません。";
      return true;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    setCustomValidMessage(check, messages) {
      if (!(check === true)) messages.push(check);
    },

    onPurchasePersonChanged() {
      const purchasePerson = this.$refs.purchasePerson[0]?.selected();
      if (purchasePerson) {
        this.purchasePersonNames = purchasePerson.userName;
      }
    },
    cellEditorPurchasePersonName() {
      return {
        selectData: this.purchasePersonList,
        placeholder: "カフェレオ担当者",
        required: true,
        autocomplete: {
          showOnFocus: true,
        },
      };
    },
  },

  async beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = await this.setColumnDefs();
  },
};
</script>
