<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onCancel">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ importCount }}件の取り込みが完了しました。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import Validation from "../../libs/validation";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import DateTypes from "../../consts/PurchaseImportDateTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { MakerAnswerConfirm, MakerReserveConfirm } from "../../consts/columns/PurchaseColumns";

export default {
  name: "MakerImport",
  props: ["PurchaseModel", "DateType"],
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  data: () => ({
    rules: {
      required: Validation.required,
      isDate: Validation.isDate,
      isCsv: Validation.isCsv,
      deliveryTime: Validation.deliveryTime,
    },
    step: 1,
    file: null,
    validImportForm: null,
    importCount: 0,
    importNumber: "",
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      titeString: "",
    },
    csvRecords: [],
    errorColmuns: [
      { headerName: "発注ID", field: "purchaseId" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
  }),
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const csvLines = await FileUtils.readAsCsv(this.file);
          this.csvRecords = [];
          console.log(csvLines);
          // csvLines.forEach((line, index) =>
          for (let index = 0; index < csvLines.length; index++) {
            let line = csvLines[index];
            if (index > 0 && line.length > 1) {
              let record = this.PurchaseModel.find((row) => row.purchaseId == line[0]);

              if (record == null) {
                record = {
                  purchaseId: line[0],
                  isRecords: false,
                };
              } else {
                record.isRecords = true;
              }

              if (this.DateType == DateTypes.ANSWER) record.deliveryTime = line[1];
              if (this.DateType == DateTypes.RESERVE) record.deliveryReservation = line[1];

              this.csvRecords.push(record);
            }
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    async onSubmit() {
      if (this.validateRow(this.csvRecords)) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.csvRecords.forEach((row) => {
            console.log(row);
            // 納品予定日
            if (this.DateType == DateTypes.ANSWER) {
              updateModels.push({
                purchaseId: row.purchaseId,
                deliveryTime: row.deliveryTime,
                updateDatetime: row.updateDate,
              });
            }

            // 納品予約
            if (this.DateType == DateTypes.RESERVE) {
              const yyyymmdd = moment(row.deliveryReservation, "YYYY/MM/DD");
              updateModels.push({
                purchaseId: row.purchaseId,
                deliveryReservation: yyyymmdd.format("YYYY-MM-DD"),
                updateDatetime: row.updateDate,
              });
            }
          });

          let uri = "";
          let uri2 = "";
          if (this.DateType == DateTypes.ANSWER) {
            uri = "purchase/answer";
            uri2 = "purchase/bulkPayloadShipdate";
          }
          if (this.DateType == DateTypes.RESERVE) {
            uri = "purchase/reserve";
            uri2 = "purchase/bulkPayloadDeliveryreservation";
          }
          // WAF対策で引数をすべてさきにAPIに送る
          // 3000件毎にスライス
          const sliceByNumber = (array, number) => {
            const length = Math.ceil(array.length / number);
            return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
          };
          let requestRecordsSplit = sliceByNumber(updateModels, 3000);
          let bulkId = null;
          // APIでマージして引数を保持
          for (let i = 0; i < requestRecordsSplit.length; i++) {
            var payloadParam = {
              orders: requestRecordsSplit[i],
              bulkId: bulkId,
            };
            console.log(payloadParam);
            const response = await this.$store.dispatch(uri2, payloadParam);
            let error = response.data?.header;
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
                bulkId = response.data.contents.bulkId;
                break;
              default:
                this.redirectError();
                break;
            }
          }
          let exec = true;
          let headers = null;
          let content = {
            bulkId: bulkId,
          };
          while (exec) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const result = await this.$store.dispatch(uri, {
              contents: content,
              header: headers,
            });

            let error = result.data?.header;

            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                exec = false;
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      purchaseId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                if (errorRows.length > 0) {
                  this.$refs.bulkErrorGrid.open({ title: this.titeString, records: errorRows });
                  this.$dialog.notify.error(this.titeString + `に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                }
                this.resultData = result.data?.contents?.orders;

                this.importCount = this.resultData?.length;
                this.step++;
                break;
              case ApiStatus.consts.PROCESSING:
                headers = { InvokeId: result.data.header.invokeId };
                break;
              default:
                exec = false;
                this.redirectError();
                break;
            }
          }
        } catch (error) {
          console.error("MakerImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(rows) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row) => {
        var error = this.onValidGrid(row);
        if (error != true) {
          errorRows.push({
            purchaseId: row.purchaseId,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: this.titeString, records: errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidGrid(row) {
      var ret = true;
      var messages = [];
      let columnName = "";
      let checkValue;

      columnName = "発注ID";
      if (!row.isRecords) this.setValidMessage(this.titeString + "の対象ではありません。", columnName, messages);

      // 納品予定日
      if (this.DateType == DateTypes.ANSWER) {
        columnName = "納品予定日";
        checkValue = row.deliveryTime;
        this.setValidMessage(this.rules.required(checkValue), columnName, messages);
        this.setValidMessage(this.rules.deliveryTime(checkValue), columnName, messages);
      }

      // 納品予約
      if (this.DateType == DateTypes.RESERVE) {
        columnName = "納品予約";
        checkValue = row.deliveryReservation;
        this.setValidMessage(this.rules.required(checkValue), columnName, messages);
        this.setValidMessage(this.rules.isDate(checkValue), columnName, messages);
      }

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onComplete() {
      this.$emit("complete", this.resultData);
      this.step = 1;
      this.file = null;
      this.resultData = null;
    },
    onCancel() {
      this.file = null;
      this.resultData = null;
      this.$emit("cancel");
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.resultData = null;
      this.step--;
    },
  },
  beforeMount() {
    if (this.DateType == DateTypes.ANSWER) {
      this.gridOptions.columnDefs = MakerAnswerConfirm;
      this.titeString = "納品予定日回答取込";
    }
    if (this.DateType == DateTypes.RESERVE) {
      this.gridOptions.columnDefs = MakerReserveConfirm;
      this.titeString = "納品予約取込";
    }
  },
};
</script>
