<template>
  <simple-history :histories="histories" :functionCategory="functionCategory"></simple-history>
</template>

<script>
import SimpleHistory from "../common/SimpleHistory.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
export default {
  name: "PurchaseReturnHistory",
  props: ["returnCommitNumber"],
  components: {
    SimpleHistory,
  },
  data() {
    return {
      histories: {},
      functionCategory: "",
    };
  },
  mounted: async function () {
    this.onSearchHistory();
  },
  watch: {
    returnCommitNumber() {
      this.onSearchHistory();
    },
  },
  methods: {
    onSearchHistory: async function () {
      try {
        if (!this.returnCommitNumber) {
          this.histories = [];
          return;
        }
        this.$store.commit("ui/loading", true);
        this.functionCategory = "returnConfirm";
        console.log("this.returnCommitNumber", this.returnCommitNumber);
        let response = await this.$store.dispatch("purchaseReturn/history", this.returnCommitNumber);

        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        console.log(response.data.contents);
        this.histories = response.data.contents.histories;
        this.$store.commit("ui/loading", false);
      } catch (error) {
        console.error("error:", this.$options._componentTag);
        //return this.redirectError();
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
