<template>
  <v-container fluid style="height: 100%">
    <simple-history :histories="histories" :functionCategory="functionCategory"></simple-history>
  </v-container>
</template>

<script>
import SimpleHistory from "../common/SimpleHistory.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
export default {
  name: "DeficiencyHistory",
  props: ["deficiencyNumber"],
  components: {
    SimpleHistory,
  },
  data() {
    return {
      histories: {},
      functionCategory: "",
    };
  },
  mounted: async function () {
    this.onSearchHistory();
  },
  watch: {
    deficiencyNumber() {
      this.onSearchHistory();
    },
  },
  methods: {
    onSearchHistory: async function () {
      try {
        console.log("this.deficiencyNumber", this.deficiencyNumber);
        if (!this.deficiencyNumber) {
          this.histories = [];
          return;
        }
        this.$store.commit("ui/loading", true);
        this.functionCategory = "deficiency";
        console.log("this.deficiencyNumber", this.deficiencyNumber);
        let response = await this.$store.dispatch("deficiency/history", this.deficiencyNumber);

        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        console.log(response.data.contents);
        this.histories = response.data.contents.histories;
        this.$store.commit("ui/loading", false);
      } catch (error) {
        console.error("error:", this.$options._componentTag);
        //return this.redirectError();
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
