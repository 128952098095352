<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-btn icon v-if="infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-minimize</v-icon></v-btn
        >
        <v-btn icon v-if="!infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2" hide-slider>
          <v-tab>{{ isCafereoUser ? "発注" : "受注" }}詳細</v-tab>
          <v-tab>履歴</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item>
            <purchase-details :style="[gridStyle, overflow]" :details="details"></purchase-details>
          </v-tab-item>
          <v-tab-item>
            <purchase-history :purchaseId="purchaseId"></purchase-history>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PurchaseDetails from "./PurchaseDetails.vue";
import PurchaseHistory from "./PurchaseHistory.vue";
export default {
  name: "PurchaseInfos",
  props: ["details", "purchaseId", "gridStyle"],
  data: () => ({
    tab: null,
    items: ["1", "2"],
    infoMaximum: false,
    overflow: { overflow: "scroll" },
  }),
  components: {
    PurchaseDetails,
    PurchaseHistory,
  },
  methods: {
    onInfoMaximum: function () {
      this.infoMaximum = !this.infoMaximum;
      this.$emit("infoMaximum-event");
    },
  },
};
</script>
