<template>
  <v-container fluid style="height: 100%">
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >在庫金額：￥{{ this.stockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="6"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >[選択]在庫金額：￥{{ this.selectStockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="stockRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col v-if="shownInfo" id="StockInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <stock-infos v-on:infoMaximum-event="infoMaximum = !infoMaximum" :details="selectedDetails"></stock-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import StockInfos from "../stock/StockInfos.vue";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import ProductTypes from "../../consts/ProductTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "PurchaseFromStocks",
  props: ["searchModel"],
  components: {
    AgGridVue,
    StockInfos,
  },
  data: () => ({
    gridStyle: { height: "95%" },
    shownInfo: false,
    infoMaximum: false,
    activeTab: null,
    selectedPageSize: 10,

    gridOptions: {
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        {
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "仕入先略称",
          field: "makerName",
        },
        { headerName: "商品ランク", field: "productLank" },
        { headerName: "JANCODE", field: "janCode", pinned: "left" },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "保管場所", field: "storageName", pinned: "left" },
        {
          headerName: "在庫総数",
          colId: "stockTotalQuantity",
          type: "dpNumericColumn",
          valueGetter: (params) => {
            return (
              params.data.stockQuantity +
              params.data.badQuantity +
              params.data.cafereoEntrustQuantity +
              params.data.ensureQuantity
            );
          },
        },
        {
          headerName: "在庫数",
          field: "stockQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "確保在庫数",
          field: "ensureQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不良在庫数",
          field: "badQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "委託在庫数(当社)",
          field: "cafereoEntrustQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "委託在庫数(MK)",
          field: "makerEntrustQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "移動予定数",
          field: "movingQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "在庫金額",
          field: "movingAmount",
          type: "dpNumericColumn",
        },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "受注単位",
          field: "orderUnit",
          type: "dpNumericColumn",
        },
        {
          headerName: "上限数",
          field: "maximumNumber",
          type: "dpNumericColumn",
        },
        { headerName: "メーカー在庫", field: "makerStock" },
        { headerName: "初回締後追加不可", field: "nonAdded", type: "dpCheckmarkColumn" },
        { headerName: "食品", field: "confectionery", type: "dpCheckmarkColumn" },
        { headerName: "賞味期限", field: "sellBy", type: "dpDateColumn" },
        { headerName: "ASIN", field: "asin" },
        { headerName: "登録日時", field: "createDatetime", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      rowSelection: "multiple",
      suppressCellSelection: true,
      pagination: true,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      localeText: AG_GRID_LOCALE_JA,
      getRowNodeId: (data) => data.stockId,
    },
    stockRecords: [],
    selectionRows: [],
    updateDialog: false,
    updateModel: {
      productCode: null,
      productName: null,
      storageName: null,
      stockQuantity: null,
      inputQuantity: null,
    },
    transferDialog: false,
    transferModel: {
      productCode: null,
      productName: null,
      currentStorageName: null,
      currentStockQuantity: null,
      movingStorageName: null,
      movingQuantity: null,
      movingPlanDate: null,
    },
    stockAmountTotal: 0,
    selectStockAmountTotal: 0,
  }),
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
      this.handleResize();
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("purchase/searchStock", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "発注対象一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.stocks).length === 0) {
          this.$dialog.warning({
            title: "発注対象一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        response.data.contents.stocks.map((stock) => {
          stock.purchaseQuantity = 0;
          stock.purchasePcsQuantity = 0;
          stock.retailPriceTotal = stock.purchaseQuantity * stock.retailPrice;
          stock.purchasePriceTotal = stock.purchaseQuantity * stock.purchasePrice;
        });
        this.gridOptions.api.setRowData(response.data.contents.stocks);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.stockAmountTotal = 0;
        this.selectStockAmountTotal = 0;
        for (let row of response.data.contents.stocks) {
          this.stockAmountTotal += row.movingAmount;
        }
      } catch (error) {
        console.error("StockSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.selectStockAmountTotal = 0;
      for (let row of this.selectionRows) {
        this.selectStockAmountTotal += row.movingAmount;
      }
      this.$emit("onSelectionChanged", this.selectionRows);
    },
  },
};
</script>
