<template>
  <!--
    Note: 配置する要素が単一の場合は、当コンポーネントを使用せずに、
    `fixed`、`right`、`bottom`、`z-index`を記述することでも可能(ボタンで検証済み)
    <v-btn fixed right bottom style="z-index: 4"><v-icon>mdi-check</v-icon>CHECK</v-btn>
  -->
  <div class="pa-2" style="position: fixed; bottom: 0; right: 0; z-index: 4"><slot></slot></div>
</template>

<script>
export default {
  name: "BottomRightAttacher",
};
</script>
